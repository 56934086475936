import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-arbeitsbericht',
  templateUrl: './form-arbeitsbericht.component.html',
  styleUrls: ['./form-arbeitsbericht.component.scss'],
})
export class FormArbeitsberichtComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() anlagenreihe: string;
  constructor() {}

  ngOnInit() {}
}
