import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-bestaetigung',
  templateUrl: './dialog-bestaetigung.component.html',
  styleUrls: ['./dialog-bestaetigung.component.scss'],
})
export class DialogBestaetigungComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {}
}
