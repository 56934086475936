import { newId } from '../util';
import { formatISO, parseISO } from 'date-fns';

export interface Zusatztermin {
  ZEITRAUMVON: string;
  ZEITRAUMBIS: string;
  MITARBEITERNAME: string;
  INFOEINTRAGKURZ: string;
}

export interface Urlaubstermin {
  TAGSTART: string;
  TAGENDE: string;
  MITARBEITERNAME: string;
  KENNZEICHEN: string;
  BEMERKUNGEN: string;
}

export interface Termin {
  id: string;
  start: string;
  allDay?: boolean;
  title: string;
  meta: {
    auftrag_id?: string;
    zusatztermin?: Zusatztermin;
    urlaubstermin?: Urlaubstermin;
  };
}

export function createTermin(params: Partial<Termin>) {
  return {
    id: newId(),
    start: fixISODate(params.start),
    ...params,
  } as Termin;
}

function fixISODate(date: string): string {
  return formatISO(parseISO(date));
}
