import { Injectable } from '@angular/core';
import { AuftragStore } from './auftrag.store';
import { FormularStore } from './formular.store';
import { TerminStore } from './termin.store';

@Injectable({ providedIn: 'root' })
export class StateService {
  constructor(
    private auftragStore: AuftragStore,
    private formularStore: FormularStore,
    private terminStore: TerminStore
  ) {}

  wipe() {
    this.auftragStore.reset();
    this.formularStore.reset();
    this.terminStore.reset();
  }
}
