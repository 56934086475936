import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

export interface UpdateAvailableSnackbarComponentData {
  countdown: Observable<number>;
}

@Component({
  selector: 'app-update-available-snackbar',
  templateUrl: './update-available-snackbar.component.html',
  styleUrls: ['./update-available-snackbar.component.scss'],
})
export class UpdateAvailableSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: UpdateAvailableSnackbarComponentData,
    private matSnackBarRef: MatSnackBarRef<UpdateAvailableSnackbarComponent>
  ) {}

  action(): void {
    this.matSnackBarRef.dismissWithAction();
  }
}
