<mat-tab-group [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field appearance="standard">
      <mat-label>Anzahl Personen</mat-label>
      <input matInput type="number" formControlName="anzahlPersonen" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>
        Anzeige im Display
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="anzeigeImDisplay"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Laufzeiten" formGroupName="laufzeiten">
    <div fxLayout="row" fxLayoutGap="1em">
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Zyklen Ist</mat-label>
          <input matInput type="number" formControlName="zyklenIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Startzeit Ist</mat-label>
          <input matInput type="number" formControlName="startzeitIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 1 Ein Ist</mat-label>
          <input matInput type="number" formControlName="belueftung1EinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 1 Aus Ist</mat-label>
          <input matInput type="number" formControlName="belueftung1AusIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 2 Ein Ist</mat-label>
          <input matInput type="number" formControlName="belueftung2EinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 2 Aus Ist</mat-label>
          <input matInput type="number" formControlName="belueftung2AusIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammrückführung Ein Ist</mat-label>
          <input matInput type="number" formControlName="schlammrueckfuehrungEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasser Ein Ist</mat-label>
          <input matInput type="number" formControlName="klarwasserEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Max. Druck Ist</mat-label>
          <input matInput type="number" formControlName="maxDruckIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Min. Druck Ist</mat-label>
          <input matInput type="number" formControlName="minDruckIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Arbeitsdruck Ist</mat-label>
          <input matInput type="number" formControlName="arbeitsdruckIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>HW-Schwelle Ist</mat-label>
          <input matInput type="number" formControlName="hwSchwelleIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>NW-Schwelle Ist</mat-label>
          <input matInput type="number" formControlName="nwSchwelleIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>änd. Wsp Ist</mat-label>
          <input matInput type="number" formControlName="aendWspIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>p. HW-Erk. Ist</mat-label>
          <input matInput type="number" formControlName="pHwErkIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>p v. Abs. Ist</mat-label>
          <input matInput type="number" formControlName="pvAbsIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>p n. Abs. Ist</mat-label>
          <input matInput type="number" formControlName="pnAbsIst" />
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Zyklen Veränderung</mat-label>
          <input matInput type="number" formControlName="zyklenVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Startzeit Veränderung</mat-label>
          <input matInput type="number" formControlName="startzeitVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 1 Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="belueftung1EinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 1 Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="belueftung1AusVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 2 Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="belueftung2EinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung 2 Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="belueftung2AusVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammrückführung Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="schlammrueckfuehrungEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasser Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="klarwasserEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Max. Druck Veränderung</mat-label>
          <input matInput type="number" formControlName="maxDruckVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Min. Druck Veränderung</mat-label>
          <input matInput type="number" formControlName="minDruckVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Arbeitsdruck Veränderung</mat-label>
          <input matInput type="number" formControlName="arbeitsdruckVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>HW-Schwelle Veränderung</mat-label>
          <input matInput type="number" formControlName="hwSchwelleVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>NW-Schwelle Veränderung</mat-label>
          <input matInput type="number" formControlName="nwSchwelleVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>änd. Wsp Veränderung</mat-label>
          <input matInput type="number" formControlName="aendWspVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>p. HW-Erk. Veränderung</mat-label>
          <input matInput type="number" formControlName="pHwErkVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>p v. Abs. Veränderung</mat-label>
          <input matInput type="number" formControlName="pvAbsVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>p n. Abs. Veränderung</mat-label>
          <input matInput type="number" formControlName="pnAbsVeraenderung" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Betriebsstunden" formGroupName="betriebsstunden">
    <mat-form-field appearance="standard">
      <mat-label>Belüfter B1</mat-label>
      <input matInput type="number" formControlName="beluefterB1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Pumpe 2</mat-label>
      <input matInput type="number" formControlName="pumpe2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Pumpe 3</mat-label>
      <input matInput type="number" formControlName="pumpe3" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Pumpe 4</mat-label>
      <input matInput type="number" formControlName="pumpe4" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Belüfter B1 Test (A)</mat-label>
      <input matInput type="number" formControlName="beluefterB1TestAmpere" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Pumpe 2 Test (A)</mat-label>
      <input matInput type="number" formControlName="pumpe2TestAmpere" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Pumpe 3 Test (A)</mat-label>
      <input matInput type="number" formControlName="pumpe3TestAmpere" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Pumpe 4 Test (A)</mat-label>
      <input matInput type="number" formControlName="pumpe4TestAmpere" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Schnelltest" formGroupName="schnelltest">
    <app-form-ja-nein formControlName="beluefterB1OK">Belüfter B1 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpe2OK">Pumpe 2 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpe3OK">Pumpe 3 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpe4OK">Pumpe 4 OK</app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Sichtkontrolle" formGroupName="sichtkontrolle">
    <mat-form-field appearance="standard">
      <mat-label>Färbung der Biologie</mat-label>
      <input matInput type="text" formControlName="faerbungDerBiologie" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm im Reaktor</mat-label>
      <input matInput type="number" formControlName="belebtschlammImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Schwimmschlamm im Reaktor</mat-label>
      <input matInput type="number" formControlName="schwimmschlammImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration im Reaktor</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Temperatur im Reaktor</mat-label>
      <input matInput type="number" formControlName="temperaturImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>pH-Wert der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="pHWertDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        CSB
      </mat-label>
      <input matInput formControlName="CSB" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        BSB5
      </mat-label>
      <input matInput formControlName="BSB5" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Temperatur der Klarwasserprobe (°C)</mat-label>
      <input matInput type="number" formControlName="temperaturDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Absetzbare Stoffe der Klarwasserprobe</mat-label>
      <input matInput formControlName="absetzbareStoffeDerKlarwasserprobe" />
    </mat-form-field>

    <app-form-ja-nein formControlName="auslaufAusDerKlaeranlage"
      >Auslauf aus der Kläranlage</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="zulaufUeberstaut">Zulauf Überstaut</app-form-ja-nein>
    <app-form-ja-nein formControlName="beUndEntlueftungOK">Be- und Entlüftung OK</app-form-ja-nein>

    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK1 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK1" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK2 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK2" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK3 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK3" />
    </mat-form-field>

    <app-form-ja-nein formControlName="schlammabfuhrOK">Schlammabfuhr OK</app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Bemerkungen" formGroupName="bemerkungen">
    <mat-form-field appearance="standard">
      <mat-label>
        Störungsmeldungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="stoerungsmeldungen"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Bemerkungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="bemerkungen"
      ></textarea>
    </mat-form-field>
  </mat-tab>
</mat-tab-group>
