import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
})
export class AuthDialogComponent implements OnInit {
  constructor(private dRef: MatDialogRef<AuthDialogComponent>) {}

  ngOnInit() {}

  onAuth() {
    this.dRef.disableClose = false;

    this.dRef.close();
  }
}
