<mat-tab-group [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Steuerungstyp</mat-label>
      <input matInput formControlName="steuerungstyp" />
    </mat-form-field>
  </mat-tab>

  <!-- todo: wartungsbericht betriebsstundenerfassung-->
  <mat-tab label="Betriebsstunden">
    <h4>Bitte geben Sie die Betriebsstunden über die Checkliste ein.</h4>
  </mat-tab>

  <mat-tab label="Wartungsarbeiten" formGroupName="wartungsarbeiten">
    <app-form-select-pruefung formControlName="funktionskontrolleAggregate"
      >Funktionskontrolle Aggregate
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="funktionskontrolleSteuerung"
      >Funktionskontrolle Steuerung
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pruefungBetriebsbuch"
      >Pruefung Betriebsbuch</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="SichtkontrolleTkFestbettReaktor"
      >Sichtkontrolle Tk Festbettreaktor
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="schlammabtriebImAblauf"
      >Schlammabtrieb im Ablauf
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="schwimmschlammImNkb"
      >Schwimmschlamm im Nkb</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="pruefungZulauf"
      >Prüfung Zulauf</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="baulicherZustand"
      >Baulicher Zustand</app-form-select-pruefung
    >
    <app-form-ja-nein formControlName="wasserprobeGezogen">Wasserprobe gezogen</app-form-ja-nein>

    <h4>Zusätzlich Festbett</h4>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Sauerstoff Festbett (mL/L)</mat-label>
      <input matInput type="number" formControlName="pruefungSauerstoffFestbett" />
    </mat-form-field>
    <app-form-select-pruefung formControlName="pruefungLuftfilter"
      >Prüfung Luftfilter</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="pruefungLuftverteilung"
      >Prüfung Luftverteilung
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pruefungMembranbeluefter"
      >Prüfung Membranbeluefter
    </app-form-select-pruefung>

    <h4>Zusätzlich SBR-Anlage</h4>
    <app-form-select-pruefung formControlName="schwimmschlammImReaktor"
      >Schwimmschlamm im Reaktor
    </app-form-select-pruefung>
    <mat-form-field appearance="standard">
      <mat-label>Prüfung Sauerstoff SBR-Anlage</mat-label>
      <input matInput formControlName="pruefungSauerstoffSbrAnlage" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm im Reaktor</mat-label>
      <input matInput formControlName="belebtschlammImReaktor" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Messwerte" formGroupName="messwerte">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Letzte Abfuhr</mat-label>
      <input matInput formControlName="letzteAbfuhr" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen Vorklärung 1 (%)</mat-label>
      <input matInput formControlName="schlammvolumenVK1" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen Vorklärung 2 (%)</mat-label>
      <input matInput formControlName="schlammvolumenVK2" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen Vorklärung 3 (%)</mat-label>
      <input matInput formControlName="schlammvolumenVK3" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammabfuhr</mat-label>
      <input matInput formControlName="schlammAbfuhr" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>pH-Wert Zulauf</mat-label>
      <input matInput formControlName="phWertZulauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>CSB Zulauf (mg/L)</mat-label>
      <input matInput formControlName="csbZulauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>BSB5 Zulauf (mg/L)</mat-label>
      <input matInput formControlName="bsb5Zulauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Leitfähigkeit Zulauf</mat-label>
      <input matInput formControlName="leitfaehigkeitZulauf" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Ph Wert Ablauf</mat-label>
      <input matInput formControlName="phWertAblauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>CSB Ablauf (mg/L)</mat-label>
      <input matInput formControlName="csbAblauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>BSB5 Ablauf (mg/L)</mat-label>
      <input matInput formControlName="bsb5Ablauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Leitfaehigkeit Ablauf</mat-label>
      <input matInput formControlName="leitfaehigkeitAblauf" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Absetzbare Stoffe (mL/L)</mat-label>
      <input matInput formControlName="absetzbareStoffe" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Temperatur (°C)</mat-label>
      <input matInput formControlName="temperatur" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Sichttiefe (cm)</mat-label>
      <input matInput formControlName="sichttiefe" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Geruch</mat-label>
      <mat-select formControlName="geruch">
        <mat-option value="kein">kein</mat-option>
        <mat-option value="schwach">schwach</mat-option>
        <mat-option value="faulig">faulig</mat-option>
      </mat-select>
    </mat-form-field>

    <!--<mat-radio-group formControlName="geruch">-->
    <!--<mat-radio-button>kein</mat-radio-button>-->
    <!--<mat-radio-button>schwach</mat-radio-button>-->
    <!--<mat-radio-button>faulig</mat-radio-button>-->
    <!--</mat-radio-group>-->
  </mat-tab>

  <mat-tab label="Maßnahmen">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Maßnahmen</mat-label>
      <textarea
        matInput
        mat-autosize
        matAutosizeMinRows="6"
        formControlName="massnahmen"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Materialverbrauch">
    <app-form-materialverbrauch
      [form]="form.get('materialverbrauch')"
      [anlagenreihe]="anlagenreihe"
    ></app-form-materialverbrauch>
  </mat-tab>

  <mat-tab label="Zeit">
    <app-form-zeit [form]="form.get('zeit')"></app-form-zeit>
  </mat-tab>
</mat-tab-group>
