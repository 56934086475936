import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../state';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  @Output() authed = new EventEmitter<true>();

  form = this.fb.group({
    username: [null, Validators.required],
    password: [null, Validators.required],
  });

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit() {}

  async login() {
    const { username, password } = this.form.value;

    const result = await this.authService.login(username, password);

    if (result) {
      this.authed.emit(true);
      return true;
    } else {
      alert('Benutzername oder Passwort nicht korrekt.');
    }
  }
}
