import { Component, Input, OnInit } from '@angular/core';
import { Zusatztermin } from '../../state/termin.model';

@Component({
  selector: 'app-zusatztermin-card',
  templateUrl: './zusatztermin-card.component.html',
  styleUrls: ['./zusatztermin-card.component.scss'],
})
export class ZusatzterminCardComponent implements OnInit {
  @Input() zusatztermin: Zusatztermin;

  constructor() {}

  ngOnInit() {}
}
