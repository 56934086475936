import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuftragQuery } from '../../state/auftrag.query';
import { Auftrag } from '../../state/auftrag.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-auftrag',
  templateUrl: './view-auftrag.component.html',
  styleUrls: ['./view-auftrag.component.scss'],
})
export class ViewAuftragComponent implements OnInit {
  auftrag$: Observable<Auftrag>;

  constructor(route: ActivatedRoute, router: Router, auftragQuery: AuftragQuery) {
    this.auftrag$ = route.paramMap.pipe(
      map((params) => params.get('auftragId')),
      switchMap((id) => auftragQuery.selectEntity(id)),
      tap((auftrag) => {
        if (!auftrag) {
          router.navigate(['..'], { relativeTo: route });
        }
      })
    );
  }

  ngOnInit() {}
}
