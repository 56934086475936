import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-wartungsbericht-klaeranlage',
  templateUrl: './form-wartungsbericht-klaeranlage.component.html',
  styleUrls: ['./form-wartungsbericht-klaeranlage.component.scss'],
})
export class FormWartungsberichtKlaeranlageComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() anlagenreihe: string;

  ngOnInit() {}
}
