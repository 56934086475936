import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { detect } from 'detect-browser';

@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
})
export class DialogInfoComponent implements OnInit {
  version = environment.version;
  browser = detect();

  quota: number;
  usage: number;

  constructor() {}

  ngOnInit() {
    this.getStorageInfo();
  }

  async getStorageInfo() {
    const { quota, usage } = await navigator.storage.estimate();

    this.quota = quota;
    this.usage = usage;
  }
}
