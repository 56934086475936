import { Injectable } from '@angular/core';
import { AuthStore } from './auth.store';
import { MatDialog } from '@angular/material/dialog';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { AuthQuery } from './auth.query';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private authQuery: AuthQuery,
    private authStore: AuthStore,
    private dialog: MatDialog,
    private http: HttpClient
  ) { }

  forceLogin() {
    const dRef = this.dialog.open(AuthDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
    });

    return dRef.afterClosed();
  }

  logout() {
    this.authStore.update({
      session: null,
    });

    window.location.reload();
  }

  async login(username: string, password: string) {

    try {
      const result = await this.http
        .post<any>(
          environment.api_url,
          {
            email: username,
            passwort: password,
          },
          {
            params: {
              funktion: 'pruefeAnmeldungVonApp',
              email: username,
              passwort: password,
            },
          }
        )
        .toPromise();

      this.authStore.update({
        session: result.SEID,
      });

      return true;
    } catch (e) {
      return false;
    }
  }

  authedRequest(req: HttpRequest<any>): HttpRequest<any> {
    const session = this.authQuery.getSession();

    if (session) {
      return req.clone({
        setParams: {
          seid: session,
        },
      });
    } else {
      return req;
    }
  }
}
