import { DialogeService } from '../dialoge/dialoge.service';
import { Auftrag } from '../state/auftrag.model';

export interface FormularDaten {
  typ: string;
  text: string;
  dialogOeffnen: (daten) => Promise<any>;
}

export function makeFormularDaten(dialoge: DialogeService): (auftrag: Auftrag) => FormularDaten[] {
  return (auftrag: Auftrag) => {
    const unterschrift = {
      text: 'Unterschrift',
      typ: 'unterschrift',
      dialogOeffnen: async (daten: any) => dialoge.unterschrift(daten, auftrag),
    };

    const arbeitsbericht = {
      text: 'Arbeitsbericht',
      typ: 'arbeitsbericht',
      dialogOeffnen: async (daten) => dialoge.arbeitsbericht(daten, auftrag),
    };

    const wartungsberichtAbscheider = {
      text: 'Wartungsbericht',
      typ: 'wartungsberichtAbscheider',
      dialogOeffnen: async (daten) => dialoge.wartungsberichtAbscheider(daten, auftrag),
    };

    const wartungsberichtKlaeranlage = {
      text: 'Wartungsbericht',
      typ: 'wartungsberichtKlaeranlage',
      dialogOeffnen: async (daten) => dialoge.wartungsberichtKlaeranlage(daten, auftrag),
    };

    const wartungsberichtLktBioclearVario = {
      text: 'Wartungsbericht BIOclear vario',
      typ: 'wartungsberichtLktBioclearVario',
      dialogOeffnen: async (daten) => dialoge.wartungsberichtLktBioclearVario(daten, auftrag),
    };

    const wartungsberichtPumpstation = {
      text: 'Wartungsbericht',
      typ: 'wartungsberichtPumpstation',
      dialogOeffnen: async (daten) => dialoge.wartungsberichtPumpstationen(daten, auftrag),
    };

    const checklisteBioAir = {
      text: 'Checkliste BIOair',
      typ: 'checklisteBioAir',
      dialogOeffnen: async (daten) => dialoge.checklisteBioAir(daten, auftrag),
    };

    const checklisteBioClear = {
      text: 'Checkliste BIOclear',
      typ: 'checklisteBioClear',
      dialogOeffnen: async (daten) => dialoge.checklisteBioClear(daten, auftrag),
    };

    const checklisteBioClearVario = {
      text: 'Checkliste BIOclear-vario',
      typ: 'checklisteBioClearVario',
      dialogOeffnen: async (daten) => dialoge.checklisteBioClearVario(daten, auftrag),
    };

    const checklisteBioFlow = {
      text: 'Checkliste BIOflow',
      typ: 'checklisteBioFlow',
      dialogOeffnen: async (daten) => dialoge.checklisteBioFlow(daten, auftrag),
    };

    const checklisteBioVario = {
      text: 'Checkliste BIOvario',
      typ: 'checklisteBioVario',
      dialogOeffnen: async (daten) => dialoge.checklisteBioVario(daten, auftrag),
    };

    const checklisteBioLogo = {
      text: 'Checkliste BIOlogo',
      typ: 'checklisteBioLogo',
      dialogOeffnen: async (daten) => dialoge.checklisteBioLogo(daten, auftrag),
    };

    const zuordnungAnlagenreihe: { [name: string]: FormularDaten[] } = {
      'AQUA-SIMPLEXpionier': [checklisteBioVario, wartungsberichtKlaeranlage],
      'AQUA-SIMPLEXregulant': [checklisteBioVario, wartungsberichtKlaeranlage],
      'BIO-FLOW': [checklisteBioFlow, wartungsberichtKlaeranlage],
      Fettabscheider: [
        // todo fehlt eine checkliste?
        wartungsberichtAbscheider,
      ],
      Fremdanlage: [
        checklisteBioAir,
        checklisteBioClear,
        checklisteBioVario,

        wartungsberichtKlaeranlage,
      ],
      'Hydro-Born': [],
      Koaleszenzabscheider: [
        // todo fehlt eine checkliste?
        wartungsberichtAbscheider,
      ],
      'LKT-BIOair': [checklisteBioAir, wartungsberichtKlaeranlage],
      'LKT-BIOclear': [checklisteBioClear, wartungsberichtKlaeranlage],
      'LKT-BIOclear vario': [checklisteBioClearVario, wartungsberichtLktBioclearVario],
      'LKT-BIOclear vario air': [checklisteBioClearVario, wartungsberichtLktBioclearVario],
      'LKT-BIOlogo': [checklisteBioLogo, wartungsberichtKlaeranlage],
      'LKT-BIOregio': [checklisteBioVario, wartungsberichtKlaeranlage],
      'LKT-BIOsol': [checklisteBioVario, wartungsberichtKlaeranlage],
      'LKT-BIOvario': [checklisteBioVario, wartungsberichtKlaeranlage],
      'LKT-GARIO': [
        // todo fehlt eine checkliste?
        wartungsberichtPumpstation,
      ],
      'LKT-REGENline': [],
      'LKT-REGIO': [
        // todo fehlt eine checkliste?
        wartungsberichtPumpstation,
      ],
      'LKT-VARIO': [
        // todo fehlt eine checkliste?
        wartungsberichtPumpstation,
      ],
    };

    const zuordnungWartungstyp: { [name: string]: FormularDaten[] } = {
      wartung: [
        arbeitsbericht,
        // wartungsbericht über anlagentyp
      ],
      wasserprobe: [arbeitsbericht],
      montage: [arbeitsbericht],
      störung: [arbeitsbericht],
      gewährleistungseinsatz: [arbeitsbericht],
      reparaturen: [arbeitsbericht],
      'montage kläranlagen': [arbeitsbericht],
      'montage pumpstation/abscheider': [arbeitsbericht],
      'restmontage/einweisung/inbetriebnahme': [arbeitsbericht],
      kulanzeinsatz: [arbeitsbericht],
      dichtheitsprüfung: [arbeitsbericht],
      auftragswartung: [
        // wartungsbericht über anlagentyp
      ],
      sanierung: [arbeitsbericht],
    };

    const result = [
      ...(zuordnungWartungstyp[(auftrag.AUFTRAGTYP || '').toLowerCase()] || []),
      ...(zuordnungAnlagenreihe[auftrag.ANLAGENREIHE] || []),
    ];

    if (!result.length) {
      result.push(arbeitsbericht);
    }

    return [...result, unterschrift];
  };
}
