import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AuftragStore, AuftragState } from './auftrag.store';

@Injectable({ providedIn: 'root' })
export class AuftragQuery extends QueryEntity<AuftragState> {
  constructor(protected store: AuftragStore) {
    super(store);
  }

  getAbgeschlosseneIds() {
    return this.getAll({
      filterBy: (auftrag) => auftrag.STATUS === 'abgeschlossen',
    }).map((a) => a.AID);
  }
}
