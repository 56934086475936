export interface Auftrag {
  AID: string;
  KDNR: string;
  KDKURZ: string;
  EINSATZPLZ: string;
  EINSATZORT: string;
  EINSATZSTRASSE: string;
  EINSATZTERMIN: string;
  TERMINBESTAETIGT: string;
  AUFTRAGTYP: string;
  TITEL: string;
  WARTUNGSVERTRAGNR: string;
  STATUS: 'aktiv' | 'abgeschlossen';
  STANDORTLAT: string;
  STANDORTLNG: string;
  BEARNAME: string;
  ANLAGENTYP: string;
  ANLAGENREIHE: string;
  ANSCHLUSSGROESSE: string;
  TAETIGKEITENLISTE: string;
  ANALYSENLISTE: string;
  HTML_KONTAKTINFO: string;
  createdAt: string;
  updatedAt: string;
}

export function createAuftrag(params: Partial<Auftrag>) {
  return {
    ...params,
  } as Auftrag;
}
