import { Component, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-select-pruefung',
  templateUrl: './form-select-pruefung.component.html',
  styleUrls: ['./form-select-pruefung.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormSelectPruefungComponent, multi: true },
  ],
})
export class FormSelectPruefungComponent implements OnInit {
  form = new FormControl();

  onChange: any;
  onTouched: any;

  sub = new Subscription();

  constructor() {}

  ngOnInit() {
    this.sub.add(
      this.form.valueChanges.subscribe((value) => {
        if (this.onChange) {
          this.onChange(value);
          this.onTouched();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(obj: any): void {
    this.form.setValue(obj);
  }
}
