import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Material } from '../../state/material.model';
import { MaterialQuery } from '../../state/material.query';
import { FormulareService } from '../formulare.service';

@Component({
  selector: 'app-form-materialverbrauch',
  templateUrl: './form-materialverbrauch.component.html',
  styleUrls: ['./form-materialverbrauch.component.css'],
})
export class FormMaterialverbrauchComponent implements OnInit {
  materialien: Material[];
  private _anlagenreihe: string;

  get anlagenreihe(): string {
    return this._anlagenreihe;
  }

  @Input()
  set anlagenreihe(value: string) {
    this._anlagenreihe = value;
    this.materialien = this.materialQuery.getAlleZuAnlagenreihe(value);
  }

  @Input() form: FormGroup;

  constructor(private materialQuery: MaterialQuery, private formulareService: FormulareService) {}

  ngOnInit(): void {}

  getControls(): FormGroup[] {
    return (this.form.get('liste') as FormArray).controls as FormGroup[];
  }

  materialHinzufuegen() {
    (this.form.get('liste') as FormArray).push(this.formulareService.material());
  }

  materialEntfernen(group: FormGroup) {
    const array = this.form.get('liste') as FormArray;
    const index = array.controls.findIndex((ctrl) => ctrl === group);
    array.removeAt(index);
  }
}
