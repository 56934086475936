import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FormularStore, FormularState } from './formular.store';

@Injectable({ providedIn: 'root' })
export class FormularQuery extends QueryEntity<FormularState> {
  constructor(protected store: FormularStore) {
    super(store);
  }

  getByAuftragIdAndTyp(AID: string, typ: string) {
    const result = this.getAll({
      filterBy: (f) => f.auftragId === AID && f.typ === typ,
    });

    return result[0] || null;
  }

  getChecklisteByAuftragId(AID: string) {
    const result = this.getAll({
      filterBy: (f) => f.auftragId === AID && /.*checkliste.*/i.test(f.typ),
    });

    return result[0] || null;
  }
}
