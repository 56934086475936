import { NgModule } from '@angular/core';
import { FormArbeitsberichtComponent } from './form-arbeitsbericht/form-arbeitsbericht.component';
import { FormChecklisteBioFlowComponent } from './checklisten/form-checkliste-bio-flow/form-checkliste-bio-flow.component';
import { FormChecklisteLktBioairComponent } from './checklisten/form-checkliste-lkt-bioair/form-checkliste-lkt-bioair.component';
import { FormChecklisteLktBioclearComponent } from './checklisten/form-checkliste-lkt-bioclear/form-checkliste-lkt-bioclear.component';
import { FormChecklisteLktBioclearVarioComponent } from './checklisten/form-checkliste-lkt-bioclear-vario/form-checkliste-lkt-bioclear-vario.component';
import { FormChecklisteLktBiovarioComponent } from './checklisten/form-checkliste-lkt-biovario/form-checkliste-lkt-biovario.component';
import { FormWartungsberichtAbscheiderComponent } from './wartungsberichte/form-wartungsbericht-abscheider/form-wartungsbericht-abscheider.component';
import { FormWartungsberichtKlaeranlageComponent } from './wartungsberichte/form-wartungsbericht-klaeranlage/form-wartungsbericht-klaeranlage.component';
import { FormWartungsberichtLktBioclearVarioComponent } from './wartungsberichte/form-wartungsbericht-lkt-bioclear-vario/form-wartungsbericht-lkt-bioclear-vario.component';
import { FormWartungsberichtPumpstationenComponent } from './wartungsberichte/form-wartungsbericht-pumpstationen/form-wartungsbericht-pumpstationen.component';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { SharedModule } from '../shared/shared.module';
import { FormAnlagenstandortComponent } from './form-anlagenstandort/form-anlagenstandort.component';
import { FormAuftraggeberComponent } from './form-auftraggeber/form-auftraggeber.component';
import { FormDatenComponent } from './form-daten/form-daten.component';
import { FormJaNeinComponent } from './form-ja-nein/form-ja-nein.component';
import { FormSelectPruefungComponent } from './form-select-pruefung/form-select-pruefung.component';
import { FormStandortComponent } from './form-standort/form-standort.component';
import { FormZeitComponent } from './form-zeit/form-zeit.component';
import { FormChecklisteLktBioLogoComponent } from './checklisten/form-checkliste-lkt-biologo/form-checkliste-lkt-biologo.component';
import { FormMaterialverbrauchComponent } from './form-materialverbrauch/form-materialverbrauch.component';
import { MatRippleModule } from '@angular/material/core';

const formulare = [
  FormAnlagenstandortComponent,
  FormAuftraggeberComponent,
  FormDatenComponent,
  FormJaNeinComponent,
  FormSelectPruefungComponent,
  FormStandortComponent,
  FormZeitComponent,
  FormArbeitsberichtComponent,
  FormChecklisteBioFlowComponent,
  FormChecklisteLktBioairComponent,
  FormChecklisteLktBioclearComponent,
  FormChecklisteLktBioclearVarioComponent,
  FormChecklisteLktBiovarioComponent,
  FormChecklisteLktBioLogoComponent,
  FormWartungsberichtAbscheiderComponent,
  FormWartungsberichtKlaeranlageComponent,
  FormWartungsberichtLktBioclearVarioComponent,
  FormWartungsberichtPumpstationenComponent,
];

@NgModule({
  imports: [SharedModule, MatRippleModule],
  declarations: [...formulare, AutocompleteOffDirective, FormMaterialverbrauchComponent],
  exports: [...formulare],
})
export class FormulareModule {}
