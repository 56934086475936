import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Material } from './material.model';
import { MaterialStore } from './material.store';

@Injectable({ providedIn: 'root' })
export class MaterialService {
  constructor(private materialStore: MaterialStore) {}

  add(material: Material | Material[]) {
    this.materialStore.add(material);
  }

  update(id, material: Partial<Material>) {
    this.materialStore.update(id, material);
  }

  remove(id: ID) {
    this.materialStore.remove(id);
  }

  clear() {
    this.materialStore.reset();
  }
}
