<mat-tab-group [formGroup]="form">
  <!--<mat-tab label="Auftraggeber">-->
  <!--<app-form-auftraggeber [form]="form.get('auftraggeber')"></app-form-auftraggeber>-->
  <!--</mat-tab>-->

  <!--<mat-tab label="Anlagenstandort" formGroupName="anlagenstandort">-->
  <!--<app-form-anlagenstandort [form]="form.get('anlagenstandort')"></app-form-anlagenstandort>-->
  <!--</mat-tab>-->

  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Steuerungstyp</mat-label>
      <input matInput formControlName="steuerungstyp" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Ausgeführte Arbeiten" formGroupName="ausgefuehrteArbeiten">
    <app-form-ja-nein formControlName="montageKomplett">Montage komplett</app-form-ja-nein>
    <app-form-ja-nein formControlName="montageTeilweise">Montage teilweise</app-form-ja-nein>
    <app-form-ja-nein formControlName="probebetrieb">Probebetrieb</app-form-ja-nein>
    <app-form-ja-nein formControlName="inbetriebnahme">Inbetriebnahme</app-form-ja-nein>
    <app-form-ja-nein formControlName="reparatur">Reparatur</app-form-ja-nein>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Weitere Arbeiten</mat-label>
      <textarea matInput mat-autosize matAutosizeMinRows="6" formControlName="weitere"></textarea>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Zu erledigende Arbeiten</mat-label>
      <textarea
        matInput
        mat-autosize
        matAutosizeMinRows="6"
        formControlName="zuErledigen"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Einweisung erfolgt</mat-label>
      <input matInput formControlName="einweisungErfolgt" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label
        >Name
        <!-- todo: wessen name? --></mat-label
      >
      <input matInput formControlName="name" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Materialverbrauch">
    <app-form-materialverbrauch
      [form]="form.get('materialverbrauch')"
      [anlagenreihe]="anlagenreihe"
    ></app-form-materialverbrauch>
  </mat-tab>

  <mat-tab label="Zeit">
    <app-form-zeit [form]="form.get('zeit')"></app-form-zeit>
  </mat-tab>
</mat-tab-group>
