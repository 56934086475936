import { Component, Input, OnInit } from '@angular/core';
import { Auftrag } from '../../state/auftrag.model';

@Component({
  selector: 'app-auftrag-card',
  templateUrl: './auftrag-card.component.html',
  styleUrls: ['./auftrag-card.component.scss'],
})
export class AuftragCardComponent implements OnInit {
  @Input() auftrag: Auftrag;

  constructor() {}

  ngOnInit() {}
}
