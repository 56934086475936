<mat-card *ngIf="auftrag" [class.abgeschlossen]="auftrag.STATUS === 'abgeschlossen'">
  <mat-card-header [routerLink]="[auftrag.AID]">
    <svg
      mat-card-avatar
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
      />
    </svg>
    <mat-card-title
      >{{ auftrag.TITEL
      }}<span *ngIf="auftrag.STATUS === 'abgeschlossen'"
        >&nbsp;(abgeschlossen)</span
      ></mat-card-title
    >
    <mat-card-subtitle>{{
      auftrag.EINSATZTERMIN | gracefulDate: 'dd.MM.yyyy, HH:mm'
    }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="1em">
      <div fxFlex="50%">
        <table>
          <tr>
            <th>Auftragsnummer:</th>
            <td>{{ auftrag.AID }}</td>
          </tr>
          <tr *ngIf="auftrag.WARTUNGSVERTRAGNR">
            <th>Wartungsvertrag:</th>
            <td>{{ auftrag.WARTUNGSVERTRAGNR }}</td>
          </tr>
          <tr>
            <th>Kunde:</th>
            <td>{{ auftrag.KDKURZ }} ({{ auftrag.KDNR }})</td>
          </tr>
          <tr>
            <th>Adresse:</th>
            <td>
              <a
                [href]="
                  'https://www.google.com/maps/search/?api=1&query=' +
                  auftrag.STANDORTLAT +
                  ',' +
                  auftrag.STANDORTLNG
                "
                rel="noopener"
                target="_blank"
              >
                {{ auftrag.EINSATZSTRASSE }} <br />
                {{ auftrag.EINSATZPLZ }} {{ auftrag.EINSATZORT }}
              </a>
            </td>
          </tr>
        </table>

        <p></p>
        <p></p>
      </div>
      <div fxFlex="50%">
        <div>
          Tätigkeiten:
          {{
            auftrag.TAETIGKEITENLISTE
              ? auftrag.TAETIGKEITENLISTE
              : '(Keine Tätigkeiten eingetragen)'
          }}
        </div>
        <div>
          {{ auftrag.ANALYSENLISTE ? auftrag.ANALYSENLISTE : '(Keine Analysen eingetragen)' }}
        </div>
        <div [innerHTML]="auftrag.HTML_KONTAKTINFO"></div>
      </div>
    </div>
  </mat-card-content>
  <!--<mat-card-actions>-->
  <!--<button mat-button>LIKE</button>-->
  <!--<button mat-button>SHARE</button>-->
  <!--</mat-card-actions>-->
</mat-card>
