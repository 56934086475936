import { persistState } from '@datorama/akita';
import { set, get, clear } from 'idb-keyval';
import { PersistStateStorage } from '@datorama/akita/lib/persistState';
import { auditTime } from 'rxjs/operators';

const key = 'lkt-monteur-app';

const storage: PersistStateStorage = {
  getItem: (key: string) => get(key),
  setItem: (key: string, value: any) => set(key, value),
  clear: () => clear(),
};

export async function initPersistState() {
  if (localStorage.getItem(key)) {
    const item = localStorage.getItem(key);

    await storage.setItem(key, JSON.parse(item));

    localStorage.removeItem(key);
  }

  persistState({
    storage,
    key,
    include: ['auftrag', 'auth.session', 'formular', 'termin', 'material'],
    preStorageUpdateOperator: () => (src) => src.pipe(auditTime(0)),
  });
}
