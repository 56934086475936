import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Auftrag } from './auftrag.model';

export interface AuftragState extends EntityState<Auftrag> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auftrag', idKey: 'AID', resettable: true })
export class AuftragStore extends EntityStore<AuftragState> {
  constructor() {
    super();
  }
}
