<mat-tab-group [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Anzahl Personen</mat-label>
        <input matInput formControlName="anzahlPersonen" type="number" />
      </mat-form-field>
    </div>
  </mat-tab>
  <mat-tab label="Laufzeiten" formGroupName="laufzeiten">
    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Max. Befülldauer SBR P11 P21 Ist (min)</mat-label>
          <input matInput type="number" formControlName="maxBefuelldauerSBRP11P21Ist" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Denitrifikationszeit gesamt Ist (min)</mat-label>
          <input matInput type="number" formControlName="denitrifikationszeitGesamtIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Deni Start Puls Ist (sec)</mat-label>
          <input matInput type="number" formControlName="deniStartPulsIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pause Denitrifikation Ist (min)</mat-label>
          <input matInput type="number" formControlName="pauseDenitrifikationIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Deni Puls (sec)</mat-label>
          <input matInput type="number" formControlName="deniPulsIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nitrifikationszeit gesamt Ist (min)</mat-label>
          <input matInput type="number" formControlName="nitrifikationszeitGesamtIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nitri Start Puls Ist (min)</mat-label>
          <input matInput type="number" formControlName="nitriStartPulsIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pause Nitrifikation Ist (min)</mat-label>
          <input matInput type="number" formControlName="pauseNitrifikationIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nitri Belüftung Ist (min)</mat-label>
          <input matInput type="number" formControlName="nitriBelueftungIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Reaktionszyklus Ist </mat-label>
          <input matInput type="number" formControlName="reaktionsZyklIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Sedimentation Ist (min)</mat-label>
          <input matInput type="number" formControlName="sedimentationIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Max. Entleerungsdauer SBR Ist (min)</mat-label>
          <input matInput type="number" formControlName="maxEntleerungsdauerSBRIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammabzug1 Ist (sec)</mat-label>
          <input matInput type="number" formControlName="schlammabzug1Ist" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammabzug2 Ist (sec)</mat-label>
          <input matInput type="number" formControlName="schlammabzug2Ist" />
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Max. Befülldauer SBR P11 P21 Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="maxBefuelldauerSBRP11P21Veraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Denitrifikationszeit gesamt Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="denitrifikationszeitGesamtVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Deni Start Puls Veränderung (sec)</mat-label>
          <input matInput type="number" formControlName="deniStartPulsVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pause Denitrifikation Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="pauseDenitrifikationVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Deni Puls Veränderung (sec)</mat-label>
          <input matInput type="number" formControlName="deniPulsVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nitrifikationszeit gesamt Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="nitrifikationszeitGesamtVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nitri Start Puls Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="nitriStartPulsVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pause Nitrifikation Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="pauseNitrifikationVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nitri Belüftung Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="nitriBelueftungVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Reaktionszyklus Veränderung</mat-label>
          <input matInput type="number" formControlName="reaktionsZyklVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Sedimentation Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="sedimentationVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Max. Entleerungsdauer SBR Veränderung (min)</mat-label>
          <input matInput type="number" formControlName="maxEntleerungsdauerSBRVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammabzug1 Veränderung (sec)</mat-label>
          <input matInput type="number" formControlName="schlammabzug1Veraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammabzug2 Veränderung (sec)</mat-label>
          <input matInput type="number" formControlName="schlammabzug2Veraenderung" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Betriebsstunden/Stromaufnahme">
    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex="33%" formGroupName="funktion">
        <app-form-ja-nein formControlName="kompressorK1">Funktion Kompressor K1</app-form-ja-nein>
        <app-form-ja-nein formControlName="beschickungspumpeP11"
          >Funktion Beschickungspumpe P11</app-form-ja-nein
        >
        <app-form-ja-nein formControlName="klarwasserpumpeP12"
          >Funktion Klarwasserpumpe P12</app-form-ja-nein
        >
        <app-form-ja-nein formControlName="uESPumpeP13">Funktion ÜS-Pumpe P13</app-form-ja-nein>
        <app-form-ja-nein formControlName="kompressorK2">Funktion Kompressor K2</app-form-ja-nein>
        <app-form-ja-nein formControlName="beschickungspumpeP21"
          >Funktion Beschickungspumpe P21</app-form-ja-nein
        >
        <app-form-ja-nein formControlName="klarwasserpumpeP22"
          >Funktion Klarwasseerpumpe P22</app-form-ja-nein
        >
        <app-form-ja-nein formControlName="uESPumpeP23">Funktion ÜS-Pumpe P23</app-form-ja-nein>
      </div>
      <div fxFlex="33%" formGroupName="betriebsstunden">
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden Kompressor K1 (h)</mat-label>
          <input matInput type="number" formControlName="kompressorK1" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden Beschickungspumpe P11 (h)</mat-label>
          <input matInput type="number" formControlName="beschickungspumpeP11" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden Klarwasserpumpe P12 (h)</mat-label>
          <input matInput type="number" formControlName="klarwasserpumpeP12" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden ÜS Pumpe P13 (h)</mat-label>
          <input matInput type="number" formControlName="uESPumpeP13" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden Kompressor K2 (h)</mat-label>
          <input matInput type="number" formControlName="kompressorK2" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden Beschickungspumpe P21 (h)</mat-label>
          <input matInput type="number" formControlName="beschickungspumpeP21" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden Klarwasserpumpe P22 (h)</mat-label>
          <input matInput type="number" formControlName="klarwasserpumpeP22" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Betriebsstunden ÜS Pumpe P23 (h)</mat-label>
          <input matInput type="number" formControlName="uESPumpeP23" />
        </mat-form-field>
      </div>
      <div fxFlex="33%" formGroupName="stromaufnahme">
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme Kompressor K1 (A)</mat-label>
          <input matInput type="number" formControlName="kompressorK1" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme Beschickungspumpe P11 (A)</mat-label>
          <input matInput type="number" formControlName="beschickungspumpeP11" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme Klarwasserpumpe P12 (A)</mat-label>
          <input matInput type="number" formControlName="klarwasserpumpeP12" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme ÜS Pumpe P13 (A)</mat-label>
          <input matInput type="number" formControlName="uESPumpeP13" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme Kompressor K2 (A)</mat-label>
          <input matInput type="number" formControlName="kompressorK2" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme Beschickungspumpe P21 (A)</mat-label>
          <input matInput type="number" formControlName="beschickungspumpeP21" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme Klarwasserpumpe P22 (A)</mat-label>
          <input matInput type="number" formControlName="klarwasserpumpeP22" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Stromaufnahme ÜS Pumpe P23 (A)</mat-label>
          <input matInput type="number" formControlName="uESPumpeP23" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Sichtkontrolle" formGroupName="sichtkontrolle">
    <mat-form-field appearance="standard">
      <mat-label>Färbung der Biologie Reaktor 1</mat-label>
      <input matInput formControlName="faerbungDerBiologieReaktor1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm Im Reaktor 1</mat-label>
      <input matInput type="number" formControlName="belebtschlammImReaktor1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Schwimmschlamm Im Reaktor 1</mat-label>
      <input matInput type="number" formControlName="schwimmschlammImReaktor1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration Im Reaktor 1</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationImReaktor1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Temperatur Im Reaktor 1</mat-label>
      <input matInput type="number" formControlName="temperaturImReaktor1" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Färbung der Biologie Reaktor 2</mat-label>
      <input matInput formControlName="faerbungDerBiologieReaktor2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm Im Reaktor 2</mat-label>
      <input matInput type="number" formControlName="belebtschlammImReaktor2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Schwimmschlamm Im Reaktor 2</mat-label>
      <input matInput type="number" formControlName="schwimmschlammImReaktor2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration Im Reaktor 2</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationImReaktor2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Temperatur Im Reaktor 2</mat-label>
      <input matInput type="number" formControlName="temperaturImReaktor2" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>pH-Wert Der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="pHWertDerKlarwasserprobe" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration Der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationDerKlarwasserprobe" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Absetzbare Stoffe Der Klarwasserprobe</mat-label>
      <input matInput formControlName="absetzbareStoffeDerKlarwasserprobe" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>CSB</mat-label>
      <input matInput formControlName="CSB" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>BSB5</mat-label>
      <input matInput formControlName="BSB5" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Temperatur der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="temperaturDerKlarwasserprobe" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Leitfähigkeit</mat-label>
      <input matInput formControlName="leitfaehigkeit" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Temperatur im Ablauf</mat-label>
      <input matInput type="number" formControlName="temperaturImAblauf" />
    </mat-form-field>
    <app-form-ja-nein formControlName="auslaufAusDerKlaeranlage"
      >Auslauf aus der Kläranlage</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="zulaufUebersstaut">Zulauf überstaut</app-form-ja-nein>
    <app-form-ja-nein formControlName="beUndEntlueftungOk">Be- und Entlüftung OK</app-form-ja-nein>
    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK 1 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInDerVKInProzentVK1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK 3 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInDerVKInProzentVK2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK 3 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInDerVKInProzentVK3" />
    </mat-form-field>

    <app-form-ja-nein formControlName="schlammabfuhr">Schlammabfuhr</app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Höheneinstellung" formGroupName="einstellungen">
    <mat-form-field appearance="standard">
      <mat-label>Beschickungspumpe P11 Ist</mat-label>
      <input matInput type="number" formControlName="beschickungspumpeP11Ist" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Beschickungspumpe P21 Ist</mat-label>
      <input matInput type="number" formControlName="beschickungspumpeP21Ist" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Klarwasserpumpe P12 Ist</mat-label>
      <input matInput type="number" formControlName="klarwasserpumpeP12Ist" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Klarwasserpumpe P22 Ist</mat-label>
      <input matInput type="number" formControlName="klarwasserpumpeP22Ist" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>ÜS-Pumpe P13 Ist</mat-label>
      <input matInput type="number" formControlName="uESPumpeP13Ist" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>ÜS-Pumpe P23 Ist</mat-label>
      <input matInput type="number" formControlName="uESPumpeP23Ist" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Beschickungspumpe P11 Veränderung</mat-label>
      <input matInput type="number" formControlName="beschickungspumpeP11Veraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Beschickungspumpe P21 Veränderung</mat-label>
      <input matInput type="number" formControlName="beschickungspumpeP21Veraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Klarwasserpumpe P12 Veränderung</mat-label>
      <input matInput type="number" formControlName="klarwasserpumpeP12Veraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Klarwasserpumpe P22 Veränderung</mat-label>
      <input matInput type="number" formControlName="klarwasserpumpeP22Veraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>ÜS-Pumpe P13 Veränderung</mat-label>
      <input matInput type="number" formControlName="uESPumpeP13Veraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>ÜS-Pumpe P23 Veränderung</mat-label>
      <input matInput type="number" formControlName="uESPumpeP23Veraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Vorklärausgleichsbehälter Max Ist</mat-label>
      <input matInput type="number" formControlName="vorklaerAusgleichsbehaelterMaxIst" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Vorklärausgleichsbehälter Min Ist</mat-label>
      <input matInput type="number" formControlName="vorklaerAusgleichsbehaelterMinIst" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 1 Max Ist</mat-label>
      <input matInput type="number" formControlName="sBRReaktor1MaxIst" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 1 Min Ist</mat-label>
      <input matInput type="number" formControlName="sBRReaktor1MinIst" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 2 Max Ist</mat-label>
      <input matInput type="number" formControlName="sBRReaktor2MaxIst" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 2 Min Ist</mat-label>
      <input matInput type="number" formControlName="sBRReaktor2MinIst" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Vorklärausgleichsbehälter Max Veränderung</mat-label>
      <input matInput type="number" formControlName="vorklaerAusgleichsbehaelterMaxVeraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Vorklärausgleichsbehälter Min Veränderung</mat-label>
      <input matInput type="number" formControlName="vorklaerAusgleichsbehaelterMinVeraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 1 Max Veränderung</mat-label>
      <input matInput type="number" formControlName="sBRReaktor1MaxVeraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 1 Min Veränderung</mat-label>
      <input matInput type="number" formControlName="sBRReaktor1MinVeraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 2 Max Veränderung</mat-label>
      <input matInput type="number" formControlName="sBRReaktor2MaxVeraenderung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>sBR Reaktor 2 Min Veränderung</mat-label>
      <input matInput type="number" formControlName="sBRReaktor2MinVeraenderung" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Mengenmessung" formGroupName="mengenmessung">
    <mat-form-field appearance="standard">
      <mat-label>Wassermenge</mat-label>
      <input matInput type="number" formControlName="wassermenge" />
    </mat-form-field>
    <app-form-ja-nein formControlName="funktionOK">Funktion OK</app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Phosphatfällung" formGroupName="phosphatfaellung">
    <app-form-ja-nein formControlName="funktionOK">Funktion OK</app-form-ja-nein>
    <mat-form-field appearance="standard">
      <mat-label>Betriebsstunden</mat-label>
      <input matInput type="number" formControlName="betriebsstunden" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Dosiermengen Phosphatfällung S je Minute</mat-label>
      <input matInput type="number" formControlName="dosiermengenPFaellungSJeMinute" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Füllzeit des SBRReaktors in Minuten</mat-label>
      <input matInput type="number" formControlName="fuellzeitDesSBRReaktorsInMInuten" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Fällmittel</mat-label>
      <input matInput type="number" formControlName="faellmittel" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Aktueller Fällmittelvorrat</mat-label>
      <input matInput type="number" formControlName="aktuellerFaellmittelvorrat" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="UV-Desinfektion" formGroupName="uvDesinfektion">
    <app-form-ja-nein formControlName="funktionOK">Funktion OK</app-form-ja-nein>
    <mat-form-field appearance="standard">
      <mat-label>Betriebsstunden</mat-label>
      <input matInput type="number" formControlName="betriebsstunden" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Bemerkungen" formGroupName="bemerkungen">
    <mat-form-field appearance="standard">
      <mat-label>
        Störungsmeldungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="stoerungsmeldungen"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Bemerkungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="bemerkungen"
      ></textarea>
    </mat-form-field>
  </mat-tab>
</mat-tab-group>
