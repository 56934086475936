import { NgModule } from '@angular/core';
import { DialogChecklisteLktBioairComponent } from './dialog-checkliste-lkt-bioair/dialog-checkliste-lkt-bioair.component';
import { DialogChecklisteLktBioclearComponent } from './dialog-checkliste-lkt-bioclear/dialog-checkliste-lkt-bioclear.component';
import { DialogChecklisteLktBioclearVarioComponent } from './dialog-checkliste-lkt-bioclear-vario/dialog-checkliste-lkt-bioclear-vario.component';
import { DialogChecklisteLktBioflowComponent } from './dialog-checkliste-lkt-bioflow/dialog-checkliste-lkt-bioflow.component';
import { DialogChecklisteLktBiovarioComponent } from './dialog-checkliste-lkt-biovario/dialog-checkliste-lkt-biovario.component';
import { DialogUnterschriftComponent } from './dialog-unterschrift/dialog-unterschrift.component';
import { DialogWartungsberichtAbscheiderComponent } from './dialog-wartungsbericht-abscheider/dialog-wartungsbericht-abscheider.component';
import { DialogWartungsberichtKlaeranlageComponent } from './dialog-wartungsbericht-klaeranlage/dialog-wartungsbericht-klaeranlage.component';
import { DialogWartungsberichtLktBioclearVarioComponent } from './dialog-wartungsbericht-lkt-bioclear-vario/dialog-wartungsbericht-lkt-bioclear-vario.component';
import { DialogWartungsberichtPumpstationenComponent } from './dialog-wartungsbericht-pumpstationen/dialog-wartungsbericht-pumpstationen.component';
import { DialogArbeitsberichtComponent } from './dialog-arbeitsbericht/dialog-arbeitsbericht.component';
import { DialogChecklistePionierComponent } from './dialog-checkliste-pionier/dialog-checkliste-pionier.component';
import { SharedModule } from '../shared/shared.module';
import { FormulareModule } from '../formulare/formulare.module';
import { SignaturePadModule } from '../signature-pad/signature-pad.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogBestaetigungComponent } from './dialog-bestaetigung/dialog-bestaetigung.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { DialogChecklisteLktBioLogoComponent } from './dialog-checkliste-lkt-biologo/dialog-checkliste-lkt-biologo.component';

const dialoge = [
  DialogChecklisteLktBioairComponent,
  DialogChecklisteLktBioclearComponent,
  DialogChecklisteLktBioclearVarioComponent,
  DialogChecklisteLktBioflowComponent,
  DialogChecklisteLktBiovarioComponent,
  DialogChecklisteLktBioLogoComponent,
  DialogUnterschriftComponent,
  DialogWartungsberichtAbscheiderComponent,
  DialogWartungsberichtKlaeranlageComponent,
  DialogWartungsberichtLktBioclearVarioComponent,
  DialogWartungsberichtPumpstationenComponent,
  DialogArbeitsberichtComponent,
  DialogChecklistePionierComponent,
  DialogBestaetigungComponent,
  DialogInfoComponent,
];

@NgModule({
  imports: [SharedModule, FormulareModule, SignaturePadModule, MatDialogModule],
  declarations: [...dialoge],
  entryComponents: [...dialoge],
})
export class DialogeModule {}
