<ng-container [formGroup]="form">
  <mat-tab-group [@.disabled]="true">
    <mat-tab label="Daten" formGroupName="daten">
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Anzahl Personen</mat-label>
          <input matInput type="number" formControlName="anzahlPersonen" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Anzeige im Display</mat-label>
          <textarea
            matInput
            mat-autosize
            matAutosizeMinRows="4"
            formControlName="anzeigeImDisplay"
          ></textarea>
        </mat-form-field>
      </div>
    </mat-tab>

    <mat-tab label="Laufzeiten" formGroupName="laufzeiten">
      <div fxLayout="row" fxLayoutGap="2em">
        <div fxFlex="50%">
          <h2>Ist-Wert</h2>

          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Eins Ein Ist</mat-label>
            <input matInput type="number" formControlName="ventilV1EinsEinIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Eins Aus Ist</mat-label>
            <input matInput type="number" formControlName="ventilV1EinsAusIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 Eins Ein Ist</mat-label>
            <input matInput type="number" formControlName="kompressorK3EinsEinIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 Eins Aus Ist</mat-label>
            <input matInput type="number" formControlName="kompressorK3EinsAusIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Eins Ein Ist</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2EinsEinIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Eins Aus Ist</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2EinsAusIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Zwei Ein Ist</mat-label>
            <input matInput type="number" formControlName="ventilV1ZweiEinIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Zwei Aus Ist</mat-label>
            <input matInput type="number" formControlName="ventilV1ZweiAusIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 ZweiEinIst</mat-label>
            <input matInput type="number" formControlName="kompressorK3ZweiEinIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 ZweiAusIst</mat-label>
            <input matInput type="number" formControlName="kompressorK3ZweiAusIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Zwei Ein Ist</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2ZweiEinIst" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Zwei Aus Ist</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2ZweiAusIst" />
          </mat-form-field>
        </div>
        <div fxFlex="50%">
          <h2>Veränderung</h2>

          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Eins Ein Veränderung</mat-label>
            <input matInput type="number" formControlName="ventilV1EinsEinVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Eins Aus Veränderung</mat-label>
            <input matInput type="number" formControlName="ventilV1EinsAusVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 Eins Ein Veränderung</mat-label>
            <input matInput type="number" formControlName="kompressorK3EinsEinVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 Eins Aus Veränderung</mat-label>
            <input matInput type="number" formControlName="kompressorK3EinsAusVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Eins Ein Veränderung</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2EinsEinVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Eins Aus Veränderung</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2EinsAusVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Zwei Ein Veränderung</mat-label>
            <input matInput type="number" formControlName="ventilV1ZweiEinVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Ventil V1 Zwei Aus Veränderung</mat-label>
            <input matInput type="number" formControlName="ventilV1ZweiAusVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 Zwei Ein Veränderung</mat-label>
            <input matInput type="number" formControlName="kompressorK3ZweiEinVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Kompressor K3 Zwei Aus Veränderung</mat-label>
            <input matInput type="number" formControlName="kompressorK3ZweiAusVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Zwei Ein Veränderung</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2ZweiEinVeraenderung" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Pufferpumpe P2 Zwei Aus Veränderung</mat-label>
            <input matInput type="number" formControlName="pufferpumpeP2ZweiAusVeraenderung" />
          </mat-form-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Betriebsstunden" formGroupName="betriebsstunden">
      <mat-form-field appearance="standard">
        <mat-label>Ventil V1 PumpeP1</mat-label>
        <input matInput type="number" formControlName="ventilV1PumpeP1" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Kompressor K3 </mat-label>
        <input matInput type="number" formControlName="kompressorK3" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Pufferpumpe P2 </mat-label>
        <input matInput type="number" formControlName="pufferpumpeP2" />
      </mat-form-field>
    </mat-tab>

    <mat-tab label="Schnelltest" formGroupName="schnelltest">
      <app-form-ja-nein formControlName="kompressorK3OK">Kompressor K3 OK</app-form-ja-nein>

      <app-form-ja-nein formControlName="schlammrueckfuehrungOK"
        >Schlammrückführung OK</app-form-ja-nein
      >

      <app-form-ja-nein formControlName="pufferpumpeOK">Pufferpumpe OK</app-form-ja-nein>
    </mat-tab>

    <mat-tab label="Sichtkontrolle" formGroupName="sichtkontrolle">
      <mat-form-field appearance="standard">
        <mat-label>Färbung der Biologie</mat-label>
        <input matInput type="text" formControlName="faerbungDerBiologie" />
      </mat-form-field>

      <app-form-ja-nein formControlName="blasenbildBeluefterstellungOK"
        >Blasenbild Belüfterstellung OK</app-form-ja-nein
      >
      <mat-form-field appearance="standard">
        <mat-label>Besuchs auf dem Festbett</mat-label>
        <input matInput type="number" formControlName="bewuchsAufDemFestbett" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Schwimmerschlamm im Festbett</mat-label>
        <input matInput type="number" formControlName="schwimmerschlammImFestbett" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Schwimmerschlamm im Puffer</mat-label>
        <input matInput type="number" formControlName="schwimmerschlammImPuffer" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Schwimmerschlamm in der NK</mat-label>
        <!-- todo NK? -->
        <input matInput type="number" formControlName="schwimmerschlammInDerNK" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Absetzbare Stoffe Klarwasserprobe</mat-label>
        <input matInput formControlName="absetzbareStoffeKlarwasserprobe" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>pH-Wert der Klarwasserprobe</mat-label>
        <input matInput type="number" formControlName="PhWertDerKlarwasserprobe" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>O<sub>2</sub> im Festbett vor Belüftung</mat-label>
        <input matInput type="number" formControlName="o2ImFestbettVorBelueftung" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Temperatur im Festbett vor Belüftung</mat-label>
        <input matInput type="number" formControlName="temperaturImFestbettVorBelueftung" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>O<sub>2</sub> im Festbett nach Belüftung</mat-label>
        <input matInput type="number" formControlName="o2ImFestbettNachBelueftung" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Temperatur im Festbett nach Belüftung</mat-label>
        <input matInput type="number" formControlName="temperaturImFestbettNachBelueftung" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Temperatur im Festbett</mat-label>
        <input matInput type="number" formControlName="temperaturImFestbett" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Arbeitsdruck K3</mat-label>
        <input matInput type="number" formControlName="arbeitsdruckK3" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Maximaldruck K3</mat-label>
        <input matInput type="number" formControlName="maximaldruckK3" />
      </mat-form-field>

      <app-form-ja-nein formControlName="luftfilterK3Gereinigt"
        >Luftfilter K3 gereinigt</app-form-ja-nein
      >

      <app-form-ja-nein formControlName="beUndEntlueftungOK"
        >Be- und Entlüftung OK</app-form-ja-nein
      >
      <mat-form-field appearance="standard">
        <mat-label>Dickstoffe in VKK1 (%)</mat-label>
        <input matInput type="number" formControlName="dickstoffeInVKK1InProzent" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Dickstoffe in VKK2 (%)</mat-label>
        <input matInput type="number" formControlName="dickstoffeInVKK2InProzent" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Dickstoffe in VKK3 (%)</mat-label>
        <input matInput type="number" formControlName="dickstoffeInVKK3InProzent" />
      </mat-form-field>

      <app-form-ja-nein formControlName="schlammabfuhr">Schlammabfuhr</app-form-ja-nein>
      <mat-form-field appearance="standard">
        <mat-label>CSB</mat-label>
        <input matInput formControlName="CSB" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>BSB5</mat-label>
        <input matInput formControlName="BSB5" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Temperatur der Klarwasserprobe</mat-label>
        <input matInput type="number" formControlName="temperaturDerKlarwasserprobe" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>O<sub>2</sub>-Konzentration Der Klarwasserprobe</mat-label>
        <input matInput type="number" formControlName="o2KonzentrationDerKlarwasserprobe" />
      </mat-form-field>
    </mat-tab>

    <mat-tab label="Bemerkungen" formGroupName="bemerkungen">
      <mat-form-field appearance="standard">
        <mat-label>
          Störungsmeldungen
        </mat-label>
        <textarea
          mat-autosize
          matInput
          matAutosizeMinRows="4"
          formControlName="stoerungsmeldungen"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>
          Bemerkungen
        </mat-label>
        <textarea
          mat-autosize
          matAutosizeMinRows="4"
          matInput
          formControlName="bemerkungen"
        ></textarea>
      </mat-form-field>
    </mat-tab>
  </mat-tab-group>
</ng-container>
