import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { MaterialState, MaterialStore } from './material.store';

@Injectable({ providedIn: 'root' })
export class MaterialQuery extends QueryEntity<MaterialState> {
  constructor(protected store: MaterialStore) {
    super(store);
  }

  getAlleZuAnlagenreihe(reihe: string | null) {
    const result = this.getAll({
      filterBy: (material) => material.anlagenreihen.includes(reihe),
      sortBy: 'artikelbezeichnung',
      sortByOrder: Order.ASC,
    });

    if (result.length) {
      return result;
    } else {
      return this.getAll({
        sortBy: 'artikelbezeichnung',
        sortByOrder: Order.ASC,
      });
    }
  }
}
