<mat-sidenav-container class="sidenav-container">
  <!--  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      &lt;!&ndash;<a mat-list-item routerLinkActive="route-active" [routerLinkActiveOptions]="{ exact: true }" routerLink="uebersicht">Übersicht</a>&ndash;&gt;
      <a mat-list-item routerLinkActive="route-active" [routerLinkActiveOptions]="{ exact: true }" routerLink="auftraege">Aufträge</a>
      <a mat-list-item routerLinkActive="route-active" [routerLinkActiveOptions]="{ exact: true }" routerLink="test">Test</a>
      <a mat-list-item routerLinkActive="route-active" [routerLinkActiveOptions]="{ exact: true }" routerLink="data">Daten</a>
    </mat-nav-list>
  </mat-sidenav>-->
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <!--<button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>-->
      <span [matTooltip]="'Version ' + version">LKT App</span>

      <span fxFlex></span>
      <!--<app-update-info></app-update-info>-->
      <button mat-button [matMenuTriggerFor]="menu">
        Menü
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="sync()">Synchronisieren</button>
        <mat-divider></mat-divider>
        <!--<button mat-menu-item>Einstellungen</button>-->
        <button mat-menu-item (click)="infoAnzeigen()">Info</button>
        <button mat-menu-item (click)="datenLoeschen()">Lokale Daten löschen</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="benutzerAbmelden()">Abmelden</button>
      </mat-menu>
    </mat-toolbar>
    <!-- Add Content Here -->

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
