import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { differenceInMinutes, formatISO, parseISO } from 'date-fns';
import { filter, first, switchMap } from 'rxjs/operators';
import { concat, interval, Observable, race, timer } from 'rxjs';
import {
  UpdateAvailableSnackbarComponent,
  UpdateAvailableSnackbarComponentData,
} from '../components/update-available-snackbar/update-available-snackbar.component';
import { countdown } from '../util';
import { MatSnackBar } from '@angular/material/snack-bar';

const MIN_TIME_SINCE_LAST_CHECK_MINUTES = 180;
const UPDATE_GRACE_PERIOD_SECONDS = 60;

const LAST_UPDATE_CHECK_STORAGE_KEY = 'monteure-app:last-update-check';

function getLast() {
  return localStorage.getItem(LAST_UPDATE_CHECK_STORAGE_KEY);
}

function setLast(date: string) {
  localStorage.setItem(LAST_UPDATE_CHECK_STORAGE_KEY, date);
}

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private matSnackBar: MatSnackBar
  ) {
    if (swUpdate.isEnabled) {
      this.pollUpdates();
      this.restartOnUpdateAvailable();
    }
  }

  pollUpdates() {
    const isStable = this.appRef.isStable.pipe(first((stable) => stable));

    concat(isStable, interval(1000 * 60))
      .pipe(
        filter(() => {
          const last = getLast();
          return (
            !last ||
            differenceInMinutes(Date.now(), parseISO(last)) > MIN_TIME_SINCE_LAST_CHECK_MINUTES
          );
        })
      )
      .subscribe(() => {
        this.swUpdate
          .checkForUpdate()
          .then(() => {
            setLast(formatISO(Date.now()));
          })
          .catch(() => {});
      });
  }

  restartOnUpdateAvailable(): void {
    this.swUpdate.available
      .pipe(
        first(),
        switchMap(() =>
          race(
            timer(1000 * UPDATE_GRACE_PERIOD_SECONDS),
            this.showSnackbar(countdown(UPDATE_GRACE_PERIOD_SECONDS))
          )
        )
      )
      .subscribe({ complete: () => this.activateUpdate() });
  }

  showSnackbar(cd: Observable<number>): Observable<any> {
    const data: UpdateAvailableSnackbarComponentData = {
      countdown: cd,
    };

    const sRef = this.matSnackBar.openFromComponent(UpdateAvailableSnackbarComponent, { data });

    return sRef.onAction();
  }

  activateUpdate(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
}
