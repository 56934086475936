<mat-tab-group [formGroup]="form">
  <!-- todo: wartungsbericht betriebsstundenerfassung-->
  <!--<mat-tab label="Betriebsstunden">-->
  <!--<h4>Bitte geben Sie die Betriebsstunden über die Checkliste ein.</h4>-->
  <!--</mat-tab>-->

  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Steuerungstyp</mat-label>
      <input matInput formControlName="steuerungstyp" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Betriebsstunden" formGroupName="betriebsstunden">
    <div fxLayout="row" fxLayoutGap="1em">
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Pumpe1 Betriebsstunden (h)</mat-label>
          <input matInput type="number" formControlName="p1Betriebsstunden" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pumpe2 Betriebsstunden (h)</mat-label>
          <input matInput type="number" formControlName="p2Betriebsstunden" />
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Pumpe1 Stromaufnahme (A)</mat-label>
          <input matInput type="number" formControlName="p1Stromaufnahme" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pumpe2 Stromaufnahme (A)</mat-label>
          <input matInput type="number" formControlName="p2Stromaufnahme" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Wartungsarbeiten" formGroupName="wartungsarbeiten">
    <app-form-select-pruefung formControlName="baulicherZustand"
      >Baulicher Zustand</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="wasseranfall">Wasseranfall</app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pumpengaengigesMaterialImPumpensumpf"
      >Pumpengängiges Material im Pumpensumpf</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="wasserstandImPumpensumpf"
      >Wasserstand im Pumpensumpf</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="niveausteuerung"
      >Niveausteuerung</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="schieberstellung"
      >Schieberstellung</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="funktionspruefungPumpen"
      >Funktionsprüfung Pumpen</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="kontrollUndAlarmeinrichtung"
      >Kontroll- und Alarmeinrichtung</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="betriebsstundenzaehler"
      >Betriebsstundenzähler</app-form-select-pruefung
    >
  </mat-tab>

  <mat-tab label="Maßnahmen">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Maßnahmen</mat-label>
      <textarea
        matInput
        mat-autosize
        matAutosizeMinRows="6"
        formControlName="massnahmen"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Materialverbrauch">
    <app-form-materialverbrauch
      [form]="form.get('materialverbrauch')"
      [anlagenreihe]="anlagenreihe"
    ></app-form-materialverbrauch>
  </mat-tab>

  <mat-tab label="Zeit">
    <app-form-zeit [form]="form.get('zeit')"></app-form-zeit>
  </mat-tab>
</mat-tab-group>
