<h1 mat-dialog-title>
  Wartungsbericht
</h1>
<div mat-dialog-content>
  <app-form-wartungsbericht-pumpstationen
    [form]="form"
    [anlagenreihe]="auftrag.ANLAGENREIHE"
  ></app-form-wartungsbericht-pumpstationen>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Abbrechen</button>
  <button mat-button [mat-dialog-close]="form.dirty ? form.value : null" cdkFocusInitial>
    Speichern
  </button>
</div>
