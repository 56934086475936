import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-zeit',
  templateUrl: './form-zeit.component.html',
  styleUrls: ['./form-zeit.component.scss'],
})
export class FormZeitComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit() {}
}
