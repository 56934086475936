import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthQuery, AuthService } from './state';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authQuery: AuthQuery, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.params.get('funktion') === 'pruefeAnmeldungVonApp') {
      return next.handle(req);
    }

    const makeReq = () => this.authService.authedRequest(req);

    return next.handle(makeReq()).pipe(
      catchError(() => {
        return this.authService.forceLogin().pipe(switchMap(() => next.handle(makeReq())));
      })
    );
  }
}
