import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormulareService {
  constructor(private fb: FormBuilder) {}

  arbeitsbericht(data: any) {
    const daten = this.fb.group({
      steuerungstyp: [],
    });

    const auftraggeber = this._auftraggeber();
    const anlagenstandort = this._anlagenstandort();

    const ausgefuehrteArbeiten = this.fb.group({
      montageKomplett: [],
      montageTeilweise: [],
      probebetrieb: [],
      inbetriebnahme: [],
      reparatur: [],
      weitere: [],
      zuErledigen: [],
      einweisungErfolgt: [],
      name: [], // todo(JL) Name des Einweisers oder des Eingewiesenen?
    });

    const materialverbrauch = this.materialverbrauch(data?.materialverbrauch?.liste?.length || 0);

    const zeit = this._zeit();

    return this.fb.group({
      daten,
      auftraggeber,
      anlagenstandort,
      ausgefuehrteArbeiten,
      materialverbrauch,
      zeit,
    });
  }

  checklisteBioFlow() {
    const daten = this.fb.group({
      anlagentyp: [],
      wartungsvertragnummer: [],
      datum: [],
      monteur: [],
      anzahlPersonen: [],
      anzeigeImDisplay: [],
    });

    const laufzeiten = this.fb.group({
      ventilV1EinsEinIst: [],
      ventilV1EinsAusIst: [],
      kompressorK3EinsEinIst: [],
      kompressorK3EinsAusIst: [],
      pufferpumpeP2EinsEinIst: [],
      pufferpumpeP2EinsAusIst: [],
      ventilV1ZweiEinIst: [],
      ventilV1ZweiAusIst: [],
      kompressorK3ZweiEinIst: [],
      kompressorK3ZweiAusIst: [],
      pufferpumpeP2ZweiEinIst: [],
      pufferpumpeP2ZweiAusIst: [],
      ventilV1EinsEinVeraenderung: [],
      ventilV1EinsAusVeraenderung: [],
      kompressorK3EinsEinVeraenderung: [],
      kompressorK3EinsAusVeraenderung: [],
      pufferpumpeP2EinsEinVeraenderung: [],
      pufferpumpeP2EinsAusVeraenderung: [],
      ventilV1ZweiEinVeraenderung: [],
      ventilV1ZweiAusVeraenderung: [],
      kompressorK3ZweiEinVeraenderung: [],
      kompressorK3ZweiAusVeraenderung: [],
      pufferpumpeP2ZweiEinVeraenderung: [],
      pufferpumpeP2ZweiAusVeraenderung: [],
    });

    const betriebsstunden = this.fb.group({
      ventilV1PumpeP1: [],
      kompressorK3: [],
      pufferpumpeP2: [],
    });

    const schnelltest = this.fb.group({
      kompressorK3OK: [],
      schlammrueckfuehrungOK: [],
      pufferpumpeOK: [],
    });

    const sichtkontrolle = this.fb.group({
      faerbungDerBiologie: [],
      blasenbildBeluefterstellungOK: [],
      bewuchsAufDemFestbett: [],
      schwimmerschlammImFestbett: [],
      schwimmerschlammImPuffer: [],
      schwimmerschlammInDerNK: [],
      absetzbareStoffeKlarwasserprobe: [],
      PhWertDerKlarwasserprobe: [],
      o2ImFestbettVorBelueftung: [],
      temperaturImFestbettVorBelueftung: [],
      o2ImFestbettNachBelueftung: [],
      temperaturImFestbettNachBelueftung: [],
      temperaturImFestbett: [],
      arbeitsdruckK3: [],
      maximaldruckK3: [],
      luftfilterK3Gereinigt: [],
      beUndEntlueftungOK: [],
      dickstoffeInVKK1InProzent: [],
      dickstoffeInVKK2InProzent: [],
      dickstoffeInVKK3InProzent: [],
      schlammabfuhr: [],

      CSB: [],
      BSB5: [],
      o2KonzentrationDerKlarwasserprobe: [],
      temperaturDerKlarwasserprobe: [],
    });

    return this.fb.group({
      daten,
      laufzeiten,
      betriebsstunden,
      schnelltest,
      sichtkontrolle,
      standort: this._checklisteStandort(),
      bemerkungen: this._checklisteBemerkungen(),
    });
  }

  checklisteBioClear() {
    const daten = this.fb.group({
      anlagentyp: [],
      anzahlPersonen: [],
      monteur: [],
      anzeigeImDisplay: [],
    });

    const laufzeiten = this.fb.group({
      pumpeP1EinIst: [],
      pumpeP1AusIst: [],
      pumpeP2EinIst: [],
      pumpeP2AusIst: [],
      pumpeP3EinIst: [],
      pumpeP3AusIst: [],
      nachtlaufzeitP1Ist: [],
      pufferP4EinIst: [],
      pufferP4AusIst: [],
      pumpeP1EinVeraenderung: [],
      pumpeP1AusVeraenderung: [],
      pumpeP2EinVeraenderung: [],
      pumpeP2AusVeraenderung: [],
      pumpeP3EinVeraenderung: [],
      pumpeP3AusVeraenderung: [],
      nachtlaufzeitP1Veraenderung: [],
      pufferP4EinVeraenderung: [],
      pufferP4AusVeraenderung: [],
    });

    const betriebsstunden = this.fb.group({
      pumpeP1: [],
      pumpeP2: [],
      pumpeP3: [],
      puffer: [],
      hp1h: [],
      hp1Ein: [],
      hp1Aus: [],
      hp2h: [],
      hp2Ein: [],
      hp2Aus: [],
    });

    const schnelltest = this.fb.group({
      pumpeP1OK: [],
      pumpeP2OK: [],
      pumpeP3OK: [],
      funktionSchw1Und2OK: [],
      pufferSpruehtellerOK: [],
    });

    const sichtkontrolle = this.fb.group({
      schwimmschlammInK3DerVK: [],
      schwimmschlammImPuffer: [],
      pfuetzenbildungAufDerLavaschlacke: [],
      ablagerungenAufDerLavaschlacke: [],
      verteilungUeberDemSpruehteller: [],
      speicherraumNichtUeberstaut: [],
      schwimmschlammInDerNK: [],
      temperaturDerKlarwasserprobe: [],
      pHWertDerKlarwasserprobe: [],
      absetzbareStoffeDerKlarwasserprobe: [],
      beUndEntlueftungOK: [],
      spueloeffnungenP2: [],
      dickstoffeInVKInProzentK1: [],
      dickstoffeInVKInProzentK2: [],
      dickstoffeInVKInProzentK3: [],
      schlammabfuhr: [],
      mengeSchwimmschlammImPuffer: [],
      CSB: [],
      BSB5: [],
      O2Klarwasserprobe: [],
    });

    return this.fb.group({
      daten,
      laufzeiten,
      betriebsstunden,
      schnelltest,
      sichtkontrolle,
      standort: this._checklisteStandort(),
      bemerkungen: this._checklisteBemerkungen(),
    });
  }

  checklisteBioVario() {
    const daten = this.fb.group({
      anzeigeImDisplay: [],
      anzahlPersonen: [],
    });

    const laufzeiten = this.fb.group({
      beluefterB1EinIst: [],
      sparEinIst: [],
      beluefterB1AusIst: [],
      beluefterSparAusIst: [],
      nMinIst: [],
      sminIst: [],
      beschickungEinIst: [],
      schlammrueckfuehrungEinIst: [],
      klarwasserpumpeEinIst: [],
      spruehzeit1EinIst: [],
      spruehzeit2EinIst: [],
      pumpe4Ist: [],
      beluefterB1EinVeraenderung: [],
      sparEinVeraenderung: [],
      beluefterB1AusVeraenderung: [],
      beluefterSparAusVeraenderung: [],
      nMinVeraenderung: [],
      sminVeraenderung: [],
      beschickungEinVeraenderung: [],
      schlammrueckfuehrungEinVeraenderung: [],
      klarwasserpumpeEinVeraenderung: [],
      spruehzeit1EinVeraenderung: [],
      spruehzeit2EinVeraenderung: [],
      pumpe4Veraenderung: [],
      denitrifikationEinNormalIst: [],
      denitrifikationAusNormalIst: [],
      denitrifikationDauerNormalIst: [],
      denitrifikationEinSparIst: [],
      denitrifikationAusSparIst: [],
      denitrifikationDauerSparIst: [],
      denitrifikationEinNormalVeraenderung: [],
      denitrifikationAusNormalVeraenderung: [],
      denitrifikationDauerNormalVeraenderung: [],
      denitrifikationEinSparVeraenderung: [],
      denitrifikationAusSparVeraenderung: [],
      denitrifikationDauerSparVeraenderung: [],
      maxDruckIst: [],
      arbeitsdruckIst: [],
      maxDruckVeraenderung: [],
      arbeitsdruckVeraenderung: [],
    });

    const betriebsstunden = this.fb.group({
      beluefterB1: [],
      pumpe2: [],
      pumpe3: [],
      pumpe4: [],
      beluefterB1TestAmpere: [],
      pumpe2TestAmpere: [],
      pumpe3TestAmpere: [],
      pumpe4TestAmpere: [],
    });

    const schnelltest = this.fb.group({
      beluefterB1OK: [],
      pumpe2OK: [],
      pumpe3OK: [],
      pumpe4OK: [],
    });

    const sichtkontrolle = this.fb.group({
      faerbungDerBiologie: [],
      belebtschlammImReaktor: [],
      schwimmschlammImReaktor: [],
      o2KonzentrationImReaktor: [],
      temperaturImReaktor: [],
      pHWertDerKlarwasserprobe: [],

      CSB: [],
      BSB5: [],
      absetzbareStoffeDerKlarwasserprobe: [],
      o2KonzentrationDerKlarwasserprobe: [],
      temperaturDerKlarwasserprobe: [],

      auslaufAusDerKlaeranlage: [],
      zulaufUeberstaut: [],
      beUndEntlueftungOK: [],
      dickstoffeInVKInProzentK1: [],
      dickstoffeInVKInProzentK2: [],
      dickstoffeInVKInProzentK3: [],

      schlammabfuhrOK: [],
    });

    return this.fb.group({
      daten,
      laufzeiten,
      betriebsstunden,
      schnelltest,
      sichtkontrolle,
      standort: this._checklisteStandort(),
      bemerkungen: this._checklisteBemerkungen(),
    });
  }

  checklisteBioLogo() {
    const daten = this.fb.group({
      anzeigeImDisplay: [],
      anzahlPersonen: [],
    });

    const laufzeiten = this.fb.group({
      zyklenIst: [],
      startzeitIst: [],
      belueftung1EinIst: [],
      belueftung1AusIst: [],
      belueftung2EinIst: [],
      belueftung2AusIst: [],
      schlammrueckfuehrungEinIst: [],
      klarwasserEinIst: [],
      maxDruckIst: [],
      minDruckIst: [],
      arbeitsdruckIst: [],
      hwSchwelleIst: [],
      nwSchwelleIst: [],
      aendWspIst: [],
      pHwErkIst: [],
      pvAbsIst: [],
      pnAbsIst: [],
      zyklenVeraenderung: [],
      startzeitVeraenderung: [],
      belueftung1EinVeraenderung: [],
      belueftung1AusVeraenderung: [],
      belueftung2EinVeraenderung: [],
      belueftung2AusVeraenderung: [],
      schlammrueckfuehrungEinVeraenderung: [],
      klarwasserEinVeraenderung: [],
      maxDruckVeraenderung: [],
      minDruckVeraenderung: [],
      hwSchwelleVeraenderung: [],
      nwSchwelleVeraenderung: [],
      aendWspVeraenderung: [],
      pHwErkVeraenderung: [],
      pvAbsVeraenderung: [],
      pnAbsVeraenderung: [],
      arbeitsdruckVeraenderung: [],
    });

    const betriebsstunden = this.fb.group({
      beluefterB1: [],
      pumpe2: [],
      pumpe3: [],
      pumpe4: [],
      beluefterB1TestAmpere: [],
      pumpe2TestAmpere: [],
      pumpe3TestAmpere: [],
      pumpe4TestAmpere: [],
    });

    const schnelltest = this.fb.group({
      beluefterB1OK: [],
      pumpe2OK: [],
      pumpe3OK: [],
      pumpe4OK: [],
    });

    const sichtkontrolle = this.fb.group({
      faerbungDerBiologie: [],
      belebtschlammImReaktor: [],
      schwimmschlammImReaktor: [],
      o2KonzentrationImReaktor: [],
      temperaturImReaktor: [],
      pHWertDerKlarwasserprobe: [],

      CSB: [],
      BSB5: [],
      absetzbareStoffeDerKlarwasserprobe: [],
      o2KonzentrationDerKlarwasserprobe: [],
      temperaturDerKlarwasserprobe: [],

      auslaufAusDerKlaeranlage: [],
      zulaufUeberstaut: [],
      beUndEntlueftungOK: [],
      dickstoffeInVKInProzentK1: [],
      dickstoffeInVKInProzentK2: [],
      dickstoffeInVKInProzentK3: [],

      schlammabfuhrOK: [],
    });

    return this.fb.group({
      daten,
      laufzeiten,
      betriebsstunden,
      schnelltest,
      sichtkontrolle,
      standort: this._checklisteStandort(),
      bemerkungen: this._checklisteBemerkungen(),
    });
  }
  checklisteBioClearVario() {
    const daten = this.fb.group({
      anzahlPersonen: [],
    });

    const laufzeiten = this.fb.group({
      maxBefuelldauerSBRP11P21Ist: [],
      denitrifikationszeitGesamtIst: [],
      deniStartPulsIst: [],
      deniPulsIst: [],
      pauseDenitrifikationIst: [],
      nitrifikationszeitGesamtIst: [],
      nitriStartPulsIst: [],
      pauseNitrifikationIst: [],
      nitriBelueftungIst: [],
      reaktionsZyklIst: [],
      sedimentationIst: [],
      maxEntleerungsdauerSBRIst: [],
      schlammabzug1Ist: [],
      schlammabzug2Ist: [],
      maxBefuelldauerSBRP11P21Veraenderung: [],
      denitrifikationszeitGesamtVeraenderung: [],
      deniStartPulsVeraenderung: [],
      deniPulsVeraenderung: [],
      pauseDenitrifikationVeraenderung: [],
      nitrifikationszeitGesamtVeraenderung: [],
      nitriStartPulsVeraenderung: [],
      pauseNitrifikationVeraenderung: [],
      nitriBelueftungVeraenderung: [],
      reaktionsZyklVeraenderung: [],
      sedimentationVeraenderung: [],
      maxEntleerungsdauerSBRVeraenderung: [],
      schlammabzug1Veraenderung: [],
      schlammabzug2Veraenderung: [],
    });

    const funktion = this.fb.group({
      kompressorK1: [],
      beschickungspumpeP11: [],
      klarwasserpumpeP12: [],
      uESPumpeP13: [],
      kompressorK2: [],
      beschickungspumpeP21: [],
      klarwasserpumpeP22: [],
      uESPumpeP23: [],
    });

    const betriebsstunden = this.fb.group({
      kompressorK1: [],
      beschickungspumpeP11: [],
      klarwasserpumpeP12: [],
      uESPumpeP13: [],
      kompressorK2: [],
      beschickungspumpeP21: [],
      klarwasserpumpeP22: [],
      uESPumpeP23: [],
    });

    const stromaufnahme = this.fb.group({
      kompressorK1: [],
      beschickungspumpeP11: [],
      klarwasserpumpeP12: [],
      uESPumpeP13: [],
      kompressorK2: [],
      beschickungspumpeP21: [],
      klarwasserpumpeP22: [],
      uESPumpeP23: [],
    });

    const sichtkontrolle = this.fb.group({
      faerbungDerBiologieReaktor1: [],
      belebtschlammImReaktor1: [],
      schwimmschlammImReaktor1: [],
      o2KonzentrationImReaktor1: [],
      temperaturImReaktor1: [],
      faerbungDerBiologieReaktor2: [],
      belebtschlammImReaktor2: [],
      schwimmschlammImReaktor2: [],
      o2KonzentrationImReaktor2: [],
      temperaturImReaktor2: [],
      pHWertDerKlarwasserprobe: [],
      absetzbareStoffeDerKlarwasserprobe: [],
      temperaturImAblauf: [],
      auslaufAusDerKlaeranlage: [],
      zulaufUebersstaut: [],
      beUndEntlueftungOk: [],
      dickstoffeInDerVKInProzentVK1: [],
      dickstoffeInDerVKInProzentVK2: [],
      dickstoffeInDerVKInProzentVK3: [],
      schlammabfuhr: [],

      CSB: [],
      BSB5: [],
      o2KonzentrationDerKlarwasserprobe: [],
      temperaturDerKlarwasserprobe: [],
      leitfaehigkeit: [],
    });

    const einstellungen = this.fb.group({
      beschickungspumpeP11Ist: [],
      beschickungspumpeP21Ist: [],
      klarwasserpumpeP12Ist: [],
      klarwasserpumpeP22Ist: [],
      uESPumpeP13Ist: [],
      uESPumpeP23Ist: [],
      beschickungspumpeP11Veraenderung: [],
      beschickungspumpeP21Veraenderung: [],
      klarwasserpumpeP12Veraenderung: [],
      klarwasserpumpeP22Veraenderung: [],
      uESPumpeP13Veraenderung: [],
      uESPumpeP23Veraenderung: [],
      vorklaerAusgleichsbehaelterMaxIst: [],
      vorklaerAusgleichsbehaelterMinIst: [],
      sBRReaktor1MaxIst: [],
      sBRReaktor1MinIst: [],
      sBRReaktor2MaxIst: [],
      sBRReaktor2MinIst: [],
      vorklaerAusgleichsbehaelterMaxVeraenderung: [],
      vorklaerAusgleichsbehaelterMinVeraenderung: [],
      sBRReaktor1MaxVeraenderung: [],
      sBRReaktor1MinVeraenderung: [],
      sBRReaktor2MaxVeraenderung: [],
      sBRReaktor2MinVeraenderung: [],
    });

    const mengenmessung = this.fb.group({
      wassermenge: [],
      funktionOK: [],
    });

    const phosphatfaellung = this.fb.group({
      betriebsstunden: [],
      funktionOK: [],
      dosiermengenPFaellungSJeMinute: [],
      fuellzeitDesSBRReaktorsInMInuten: [],
      faellmittel: [],
      aktuellerFaellmittelvorrat: [],
    });

    const uvDesinfektion = this.fb.group({
      betriebsstunden: [],
      funktionOK: [],
    });

    return this.fb.group({
      daten,
      laufzeiten,
      betriebsstunden,
      funktion,
      stromaufnahme,
      sichtkontrolle,
      einstellungen,
      mengenmessung,
      phosphatfaellung,
      uvDesinfektion,
      standort: this._checklisteStandort(),
      bemerkungen: this._checklisteBemerkungen(),
    });
  }

  checklisteBioAir() {
    const daten = this.fb.group({
      anzeigeImDisplay: [],
      anzahlPersonen: [],
      druck: [],
      druckStau: [],
    });

    const laufzeiten = this.fb.group({
      belueftungEinIst: [],
      sparEinIst: [],
      belueftungAusIst: [],
      sparAusIst: [],
      beschickungEinIstMbar: [],
      beschickungEinIstMin: [],
      schlammabzugEinIst: [],
      klarwasserabzugEinIstMbar: [],
      klarwasserabzugEinIstMin: [],
      druckMaxIst: [],
      druckMinIst: [],
      arbeitsdruckIst: [],

      belueftungEinVeraenderung: [],
      sparEinVeraenderung: [],
      belueftungAusVeraenderung: [],
      sparAusVeraenderung: [],
      beschickungEinVeraenderungMbar: [],
      beschickungEinVeraenderungMin: [],
      schlammabzugEinVeraenderung: [],
      klarwasserabzugEinVeraenderungMbar: [],
      klarwasserabzugEinVeraenderungMin: [],
      druckMaxVeraenderung: [],
      druckMinVeraenderung: [],
      arbeitsdruckVeraenderung: [],

      nMin: [],
      sMin: [],

      denitrifikationEinNormalIst: [],
      denitrifikationAusNormalIst: [],
      denitrifikationDauerNormalIst: [],
      denitrifikationEinSparIst: [],
      denitrifikationAusSparIst: [],
      denitrifikationDauerSparIst: [],
      denitrifikationEinNormalVeraenderung: [],
      denitrifikationAusNormalVeraenderung: [],
      denitrifikationDauerNormalVeraenderung: [],
      denitrifikationEinSparVeraenderung: [],
      denitrifikationAusSparVeraenderung: [],
      denitrifikationDauerSparVeraenderung: [],
    });

    const betriebsstunden = this.fb.group({
      b1Kompressor: [],
      v1Beschicker: [],
      v2Beluefter: [],
      v3Klarwasser: [],
      v4Schlammrueckfuehrung: [],
      netz: [],
    });

    const betriebsdruck = this.fb.group({
      v1BeschickerOK: [],
      v2BeluefterOK: [],
      v3KlarwasserOK: [],
      v4SchlammrueckfuehrungOK: [],
    });

    const sichtkontrolle = this.fb.group({
      faerbungBiologie: [],
      belebtschlammImReaktor: [],
      schwimmschlammImReaktor: [],
      o2KonzentrationImReaktor: [],
      temperaturImReaktor: [],
      absetzbareStoffeDerKlarwasserprobe: [],
      pHWertDerKlarwasserprobe: [],
      blasenbildImReaktorOK: [],
      luftglockeUndSchlauchGereinigt: [],
      beUndEntlueftungOK: [],
      dickstoffeInVKInProzentK1: [],
      dickstoffeInVKInProzentK2: [],
      dickstoffeInVKInProzentK3: [],
      schlammabfuhr: [],
      CSB: [],
      BSB5: [],
      o2KonzentrationDerKlarwasserprobe: [],
      temperaturDerKlarwasserprobe: [],
    });

    return this.fb.group({
      daten,
      laufzeiten,
      betriebsstunden,
      betriebsdruck,
      sichtkontrolle,
      standort: this._checklisteStandort(),
      bemerkungen: this._checklisteBemerkungen(),
    });
  }

  wartungsberichtPumpstation(data: any) {
    const daten = this.fb.group({
      steuerungstyp: [],
    });

    const massnahmen = this.fb.control(null);
    const materialverbrauch = this.materialverbrauch(data?.materialverbrauch?.liste?.length || 0);

    const betriebsstunden = this.fb.group({
      p1Betriebsstunden: [],
      p1Stromaufnahme: [],
      p2Betriebsstunden: [],
      p2Stromaufnahme: [],
    });

    const wartungsarbeiten = this.fb.group({
      baulicherZustand: [],
      wasseranfall: [],
      pumpengaengigesMaterialImPumpensumpf: [],
      wasserstandImPumpensumpf: [],
      niveausteuerung: [],
      schieberstellung: [],
      funktionspruefungPumpen: [],
      kontrollUndAlarmeinrichtung: [],
      betriebsstundenzaehler: [],
    });

    return this.fb.group({
      daten,
      massnahmen,
      materialverbrauch,
      betriebsstunden,
      wartungsarbeiten,
      auftraggeber: this._auftraggeber(),
      anlagenstandort: this._anlagenstandort(),
      zeit: this._zeit(),
    });
  }

  wartungsberichtAbscheider(data: any) {
    const daten = this.fb.group({
      steuerungstyp: [],
    });

    const massnahmen = this.fb.control(null);
    const materialverbrauch = this.materialverbrauch(data?.materialverbrauch?.liste?.length || 0);

    const wartungsarbeiten = this.fb.group({
      baulicherZustand: [null, Validators.required],
      pruefungKoaleszenzeinsatz: [null, Validators.required],
      pruefungSchwimmerVerschluss: [null, Validators.required],
      funktionWarnanlage: [null, Validators.required],
      pruefungAblauf: [null, Validators.required],
      pruefungBetriebsbuch: [null, Validators.required],
      wasserprobeGezogen: [null, Validators.required],
    });

    const messungen = this.fb.group({
      temperatur: [],
      phWert: [],
      schlammvolumenImSchlammfang: [],
      entsorgungSchlamm: [],
      speichervolumenFett: [],
      speichervolumenLeichtfluessigkeit: [],
      entsorgungFettOderLeichtfluessigkeit: [],
    });

    return this.fb.group({
      daten,
      massnahmen,
      materialverbrauch,
      wartungsarbeiten,
      messungen,
      auftraggeber: this._auftraggeber(),
      anlagenstandort: this._anlagenstandort(),
      zeit: this._zeit(),
    });
  }

  wartungsberichtKlaeranlage(data: any) {
    const daten = this.fb.group({
      steuerungstyp: [],
    });

    const massnahmen = this.fb.control(null);
    const materialverbrauch = this.materialverbrauch(data?.materialverbrauch?.liste?.length || 0);
    const betriebsstunden = this.fb.group({
      // todo: model betriebsstunden kläranlage
    });

    const wartungsarbeiten = this.fb.group({
      funktionskontrolleAggregate: [null, Validators.required],
      funktionskontrolleSteuerung: [null, Validators.required],
      pruefungBetriebsbuch: [null, Validators.required],
      SichtkontrolleTkFestbettReaktor: [null, Validators.required],
      schlammabtriebImAblauf: [null, Validators.required],
      schwimmschlammImNkb: [null, Validators.required],
      pruefungZulauf: [null, Validators.required],
      baulicherZustand: [null, Validators.required],
      wasserprobeGezogen: [null, Validators.required],

      // festbett
      pruefungSauerstoffFestbett: [],
      pruefungLuftfilter: [],
      pruefungLuftverteilung: [],
      pruefungMembranbeluefter: [],

      // sbr-anlage
      schwimmschlammImReaktor: [],
      pruefungSauerstoffSbrAnlage: [],
      belebtschlammImReaktor: [],
    });

    const messwerte = this.fb.group({
      letzteAbfuhr: [],
      schlammvolumenVK1: [],
      schlammvolumenVK2: [],
      schlammvolumenVK3: [],
      schlammAbfuhr: [],

      phWertZulauf: [],
      csbZulauf: [],
      bsb5Zulauf: [],
      leitfaehigkeitZulauf: [],

      phWertAblauf: [],
      csbAblauf: [],
      bsb5Ablauf: [],
      leitfaehigkeitAblauf: [],

      absetzbareStoffe: [],
      temperatur: [],
      sichttiefe: [],

      geruch: [null, Validators.required],
    });

    return this.fb.group({
      daten,
      massnahmen,
      materialverbrauch,
      // betriebsstunden, todo: betriebsstunden
      wartungsarbeiten,
      messwerte,
      auftraggeber: this._auftraggeber(),
      anlagenstandort: this._anlagenstandort(),
      zeit: this._zeit(),
    });
  }

  wartungsberichtBioClearVario(data: any) {
    const daten = this.fb.group({
      steuerungstyp: [],
    });

    const massnahmen = this.fb.control(null);
    const materialverbrauch = this.materialverbrauch(data?.materialverbrauch?.liste?.length || 0);

    const betriebsstunden = this.fb.group({
      // todo betriebsstunden BioClearVario
    });

    const wartungsarbeiten = this.fb.group({
      funktionskontrolleAggregate: [],
      funktionskontrolleSteuerung: [],
      pruefungBetriebsbuch: [],
      kontrollUndAlarmeinrichtungen: [],
      pruefungZulauf: [],
      sichtkontrolleVkPuffer: [],
      sichtkontrolleReaktor: [],
      baulicherZustand: [],
      wasserprobeGezogen: [],
      pruefungSauerstoff: [],
      pruefungLuftfilter: [],
      pruefungLuftverteilung: [],
      pruefungMembranbeluefter: [],
      schwimmschlammImReaktor: [],
      schlammabtriebImAblauf: [],
      belebtschlammInReaktor1: [],
      belebtschlammInReaktor2: [],
      veraenderungUeSAbzug: [],
    });

    const messwerte = this.fb.group({
      letzteAbfuhr: [],
      schlammvolumenVK1: [],
      schlammvolumenVK2: [],
      schlammvolumenVK3: [],
      schlammabfuhr: [],
      phWertZulauf: [],
      csbZulauf: [],
      leitfaehigkeit: [],
      phWertAblauf: [],
      csbAblauf: [],
      leitfaehigkeitAblauf: [],
      absetzbareStoffe: [],
      temperatur: [],
      truebung: [],
      geruch: [],
    });

    return this.fb.group({
      daten,
      massnahmen,
      materialverbrauch,
      // betriebsstunden, todo: betriebsstunden
      wartungsarbeiten,
      messwerte,
      auftraggeber: this._auftraggeber(),
      anlagenstandort: this._anlagenstandort(),
      zeit: this._zeit(),
    });
  }

  private _auftraggeber() {
    return this.fb.group({
      name: [],
      strasse: [],
      plz: [],
      ort: [],
      telefon: [],
    });
  }

  private _anlagenstandort() {
    return this.fb.group({
      name: [],
      strasse: [],
      plz: [],
      ort: [],
      telefon: [],
    });
  }

  private _checklisteBemerkungen() {
    return this.fb.group({
      // datum: [],
      stoerungsmeldungen: [],
      bemerkungen: [],
    });
  }

  private _checklisteStandort() {
    return this.fb.group({
      kundenname: [],
      strasse: [],
      plz: [],
      ort: [],
    });
  }

  private _zeit(): FormGroup {
    return this.fb.group({
      anreise: this.fb.group({
        start: [],
        ende: [],
        entfernung: [],
      }),
      abreise: this.fb.group({
        start: [],
        ende: [],
        entfernung: [],
      }),
      arbeit: this.fb.group({
        start: [],
        ende: [],
      }),
    });
  }

  private materialverbrauch(length: number) {
    const liste = this.fb.array([]);

    for (let i = 0; i < length; i++) {
      liste.push(this.material());
    }

    return this.fb.group({
      text: [null],
      liste,
    });
  }

  public material() {
    return this.fb.group({
      menge: [1, Validators.required],
      artikelnummer: [null, Validators.required],
    });
  }
}
