<mat-tab-group [@.disabled]="true" [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Anzahl Personen</mat-label>
        <input matInput type="number" formControlName="anzahlPersonen" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Anzeige im Display</mat-label>
        <textarea
          matInput
          mat-autosize
          matAutosizeMinRows="4"
          formControlName="anzeigeImDisplay"
        ></textarea>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Druck (mbar)</mat-label>
        <input matInput formControlName="druck" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Druck Stau (mbar)</mat-label>
        <input matInput formControlName="druckStau" />
      </mat-form-field>
    </div>
  </mat-tab>

  <mat-tab label="Laufzeiten" formGroupName="laufzeiten">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field>
        <mat-label>N min</mat-label>
        <input matInput type="number" formControlName="nMin" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>S min</mat-label>
        <input matInput type="number" formControlName="sMin" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex="50%">
        <h2>Ist-Wert</h2>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung ein</mat-label>
          <input matInput type="number" formControlName="belueftungEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung aus</mat-label>
          <input matInput type="number" formControlName="belueftungAusIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar ein</mat-label>
          <input matInput type="number" formControlName="sparEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar aus</mat-label>
          <input matInput type="number" formControlName="sparAusIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Beschickung ein (mbar)</mat-label>
          <input matInput type="number" formControlName="beschickungEinIstMbar" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Beschickung ein (min)</mat-label>
          <input matInput type="number" formControlName="beschickungEinIstMin" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammabzug ein (s)</mat-label>
          <input matInput type="number" formControlName="schlammabzugEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasserabzug ein (mbar)</mat-label>
          <input matInput type="number" formControlName="klarwasserabzugEinIstMbar" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasserabzug ein (min)</mat-label>
          <input matInput type="number" formControlName="klarwasserabzugEinIstMin" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Druck max (mbar)</mat-label>
          <input matInput type="number" formControlName="druckMaxIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Druck min (mbar)</mat-label>
          <input matInput type="number" formControlName="druckMinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Arbeitsdruck (mbar)</mat-label>
          <input matInput type="number" formControlName="arbeitsdruckIst" />
        </mat-form-field>

        <h3>Denitrifikation</h3>

        <mat-form-field appearance="standard">
          <mat-label>Normal Ein Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinNormalIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Aus Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusNormalIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Dauer Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerNormalIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Ein Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinSparIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Aus Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusSparIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Dauer Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerSparIst" />
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <h2>Veränderung</h2>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung ein</mat-label>
          <input matInput type="number" formControlName="belueftungEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüftung aus</mat-label>
          <input matInput type="number" formControlName="belueftungAusVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar ein</mat-label>
          <input matInput type="number" formControlName="sparEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar aus</mat-label>
          <input matInput type="number" formControlName="sparAusVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Beschickung ein (mbar)</mat-label>
          <input matInput type="number" formControlName="beschickungEinVeraenderungMbar" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Beschickung ein (min)</mat-label>
          <input matInput type="number" formControlName="beschickungEinVeraenderungMin" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammabzug ein (s)</mat-label>
          <input matInput type="number" formControlName="schlammabzugEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasserabzug ein (mbar)</mat-label>
          <input matInput type="number" formControlName="klarwasserabzugEinVeraenderungMbar" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasserabzug ein (min)</mat-label>
          <input matInput type="number" formControlName="klarwasserabzugEinVeraenderungMin" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Druck max (mbar)</mat-label>
          <input matInput type="number" formControlName="druckMaxVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Druck min (mbar)</mat-label>
          <input matInput type="number" formControlName="druckMinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Arbeitsdruck (mbar)</mat-label>
          <input matInput type="number" formControlName="arbeitsdruckVeraenderung" />
        </mat-form-field>

        <h3>&nbsp;</h3>

        <mat-form-field appearance="standard">
          <mat-label>Normal Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinNormalVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusNormalVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Dauer Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerNormalVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinSparVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusSparVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Dauer Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerSparVeraenderung" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Betriebsstunden" formGroupName="betriebsstunden">
    <mat-form-field appearance="standard">
      <mat-label>B1 Kompressor</mat-label>
      <input matInput type="number" formControlName="b1Kompressor" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>V1 Beschicker</mat-label>
      <input matInput type="number" formControlName="v1Beschicker" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>V2 Belüfter</mat-label>
      <input matInput type="number" formControlName="v2Beluefter" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>V3 Klarwasser</mat-label>
      <input matInput type="number" formControlName="v3Klarwasser" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>V4 Schlammrückführung</mat-label>
      <input matInput type="number" formControlName="v4Schlammrueckfuehrung" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Netz</mat-label>
      <input matInput type="number" formControlName="netz" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Betriebsdruck" formGroupName="betriebsdruck">
    <app-form-ja-nein formControlName="v1BeschickerOK">V1 Beschicker OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="v2BeluefterOK">V2 Belüfter OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="v3KlarwasserOK">V3 Klarwasser OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="v4SchlammrueckfuehrungOK"
      >V4 Schlammrückführung OK</app-form-ja-nein
    >
  </mat-tab>

  <mat-tab label="Sichtkontrolle" formGroupName="sichtkontrolle">
    <mat-form-field appearance="standard">
      <mat-label>Färbung der Biologie</mat-label>
      <input matInput type="text" formControlName="faerbungBiologie" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm im Reaktor</mat-label>
      <input matInput type="number" formControlName="belebtschlammImReaktor" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Schwimmschlamm im Reaktor</mat-label>
      <input matInput type="number" formControlName="schwimmschlammImReaktor" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration im Reaktor</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationImReaktor" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Temperatur im Reaktor</mat-label>
      <input matInput type="number" formControlName="temperaturImReaktor" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Absetzbare Stoffe der Klarwasserprobe</mat-label>
      <input matInput formControlName="absetzbareStoffeDerKlarwasserprobe" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>pH-Wert der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="pHWertDerKlarwasserprobe" />
    </mat-form-field>

    <app-form-ja-nein formControlName="blasenbildImReaktorOK"
      >Blasenbild im Reaktor OK</app-form-ja-nein
    >

    <app-form-ja-nein formControlName="luftglockeUndSchlauchGereinigt"
      >Luftglocke und Schlauch gereinigt
    </app-form-ja-nein>

    <app-form-ja-nein formControlName="beUndEntlueftungOK">Be- und Entlüftung OK</app-form-ja-nein>

    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK K1 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK K2 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK K3 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK3" />
    </mat-form-field>
    <app-form-ja-nein formControlName="schlammabfuhr">Schlammabfuhr</app-form-ja-nein>
    <mat-form-field appearance="standard">
      <mat-label>CSB</mat-label>
      <input matInput formControlName="CSB" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>BSB5</mat-label>
      <input matInput formControlName="BSB5" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Temperatur der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="temperaturDerKlarwasserprobe" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration Der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationDerKlarwasserprobe" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Bemerkungen" formGroupName="bemerkungen">
    <mat-form-field appearance="standard">
      <mat-label>
        Störungsmeldungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="stoerungsmeldungen"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Bemerkungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="bemerkungen"
      ></textarea>
    </mat-form-field>
  </mat-tab>
</mat-tab-group>
