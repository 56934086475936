<ng-container [formGroup]="form">
  <ng-container *ngFor="let group of getControls()" [formGroup]="group">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap=".5em">
      <button mat-icon-button (click)="materialEntfernen(group)">
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field appearance="standard">
        <mat-label>Menge</mat-label>
        <input matInput type="number" formControlName="menge" />
      </mat-form-field>
      <mat-form-field appearance="standard" fxFlex>
        <mat-select formControlName="artikelnummer">
          <mat-option *ngFor="let material of materialien" [value]="material.artikelnummer">{{
            material.artikelbezeichnung
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <div
    class="button-hinzuegen mat-elevation-z1"
    matRipple
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="materialHinzufuegen()"
  >
    <mat-icon>add_circle_outline</mat-icon>
  </div>
  <mat-form-field class="w-100" appearance="standard">
    <mat-label>Andere</mat-label>
    <textarea matInput mat-autosize matAutosizeMinRows="6" formControlName="text"></textarea>
  </mat-form-field>
</ng-container>
