import { Component, Input, OnInit } from '@angular/core';
import { DialogeService } from '../../dialoge/dialoge.service';
import { Auftrag } from '../../state/auftrag.model';
import { MeldungenService } from '../../meldungen.service';
import get from 'lodash.get';
import set from 'lodash.set';
import { AuftragService } from '../../state/auftrag.service';
import { FormularQuery } from '../../state/formular.query';
import { FormularService } from '../../state/formular.service';
import { createFormular } from '../../state/formular.model';

@Component({
  selector: 'app-auftrag',
  templateUrl: './auftrag.component.html',
  styleUrls: ['./auftrag.component.scss'],
})
export class AuftragComponent implements OnInit {
  get auftrag(): any {
    return this._auftrag;
  }

  @Input()
  set auftrag(value: any) {
    this._auftrag = value;

    this.formulare = this.auftragService.formulare(value) || [];
  }

  private _auftrag: Auftrag;

  formulare: { text: string; dialogOeffnen: (...args) => Promise<any> }[] = [];

  constructor(
    private formularService: FormularService,
    private formularQuery: FormularQuery,
    private auftragService: AuftragService,
    private dialogeService: DialogeService,
    private meldung: MeldungenService
  ) {}

  ngOnInit() {}

  async formularOeffnen(formular: any, auftrag: Auftrag) {
    // todo refactor: das hier in auftraege.formulardaten.ts verschieben

    const { dialogOeffnen, typ } = formular;

    const datenAlt = this.formularQuery.getByAuftragIdAndTyp(auftrag.AID, typ);

    const formAlt = (datenAlt && datenAlt.form) || {};

    /**
     * Wenn das aufgerufene Formular ein Wartungsbericht ist, wird eine vorhandene
     * Checkliste zum Auftrag gesucht und einige Werte daraus
     * in den Wartungsbericht kopiert.
     */
    if (typ && typ.includes('wartungsbericht')) {
      const datenCheckliste = this.formularQuery.getChecklisteByAuftragId(auftrag.AID);

      if (datenCheckliste) {
        const map = {
          'sichtkontrolle.pHWertDerKlarwasserprobe': 'messwerte.phWertAblauf',
          // 'sichtkontrolle.CSB': 'messwerte.csbAblauf',
          // 'sichtkontrolle.BSB5': 'messwerte.bsb5Ablauf',
          'sichtkontrolle.temperaturDerKlarwasserprobe': 'messwerte.temperatur',
          'sichtkontrolle.dickstoffeInVKInProzentK1': 'messwerte.schlammvolumenVK1',
          'sichtkontrolle.dickstoffeInDerVKInProzentVK1': 'messwerte.schlammvolumenVK1',
          'sichtkontrolle.dickstoffeInVKInProzentK2': 'messwerte.schlammvolumenVK2',
          'sichtkontrolle.dickstoffeInDerVKInProzentVK2': 'messwerte.schlammvolumenVK2',
          'sichtkontrolle.dickstoffeInVKInProzentK3': 'messwerte.schlammvolumenVK3',
          'sichtkontrolle.dickstoffeInDerVKInProzentVK3': 'messwerte.schlammvolumenVK3',

          'sichtkontrolle.schwimmschlammImReaktor': 'wartungsarbeiten.schwimmschlammImReaktor',
          'sichtkontrolle.o2KonzentrationImReaktor': 'wartungsarbeiten.pruefungSauerstoffSbrAnlage',
          'sichtkontrolle.belebtschlammImReaktor': 'wartungsarbeiten.belebtschlammImReaktor',
        };

        if (datenCheckliste && datenCheckliste.form) {
          Object.entries(map).forEach(([quelle, ziel]) => {
            if (!get(formAlt, ziel)) {
              const wert = get(datenCheckliste.form, quelle);

              if (typeof wert !== 'undefined') {
                set(formAlt, ziel, wert);
              }
            }
          });
        }
      }
    }

    const form = await dialogOeffnen(formAlt);

    if (!form) {
      return;
    }

    const datenNeu = {
      auftragId: auftrag.AID,
      typ,
      form,
    };

    if (datenAlt) {
      this.formularService.update(datenAlt.id, datenNeu);
    } else {
      this.formularService.add(createFormular(datenNeu));
    }
  }

  async auftragAbschliessen(auftrag: Auftrag) {
    const bestaetigt = await this.dialogeService.bestaetigung({
      titel: 'Auftrag abschließen',
      frage: 'Soll der Auftrag abgeschlossen werden? Er kann dann nicht mehr geändert werden.',
      okText: 'Auftrag abschließen',
      abbrechenText: 'Abbrechen',
    });

    if (!bestaetigt) {
      return;
    }

    try {
      await this.auftragService.abschliessen(auftrag);
      this.meldung.erfolg('Auftrag abgeschlossen.');
    } catch (e) {
      throw e;
    }
  }
}
