import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Auftrag } from './auftrag.model';
import { AuftragStore } from './auftrag.store';
import { makeFormularDaten } from '../services/auftraege.formulardaten';
import { DialogeService } from '../dialoge/dialoge.service';

@Injectable({ providedIn: 'root' })
export class AuftragService {
  formulare = makeFormularDaten(this.dialogeService);

  constructor(private auftragStore: AuftragStore, private dialogeService: DialogeService) {}

  add(auftrag: Auftrag | Auftrag[]) {
    this.auftragStore.add(auftrag);
  }

  update(id, auftrag: Partial<Auftrag>) {
    this.auftragStore.update(id, auftrag);
  }

  remove(id: ID) {
    this.auftragStore.remove(id);
  }

  async abschliessen(auftrag: Auftrag) {
    this.auftragStore.update(auftrag.AID, { STATUS: 'abgeschlossen' });
  }

  clear() {
    this.auftragStore.reset();
  }
}
