<!--<mat-radio-group [formControl]="form">-->
<!--<span>-->
<!--<ng-content></ng-content>-->
<!--</span>-->
<!--<mat-radio-button value="true">Ja</mat-radio-button>-->
<!--<mat-radio-button value="false">Nein</mat-radio-button>-->
<!--</mat-radio-group>-->

<mat-form-field appearance="standard">
  <mat-label>
    <ng-content></ng-content>
  </mat-label>
  <mat-select [formControl]="form">
    <mat-option value="nicht geprüft">Nicht geprüft</mat-option>
    <mat-option value="ja">Ja</mat-option>
    <mat-option value="nein">Nein</mat-option>
  </mat-select>
</mat-form-field>
