<mat-form-field appearance="standard">
  <mat-label>
    <ng-content></ng-content>
  </mat-label>
  <mat-select [formControl]="form">
    <mat-option value="ok">In Ordnung</mat-option>
    <mat-option value="maengel_vorhanden">Mängel vorhanden</mat-option>
    <mat-option value="nicht_geprueft_nicht_vorhanden">Nicht geprüft / nicht vorhanden</mat-option>
  </mat-select>
</mat-form-field>
