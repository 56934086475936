<mat-tab-group [formGroup]="form">
  <!--<mat-tab label="Auftraggeber">-->
  <!--<app-form-auftraggeber [form]="form.get('auftraggeber')"></app-form-auftraggeber>-->
  <!--</mat-tab>-->

  <!--<mat-tab label="Anlagenstandort" formGroupName="anlagenstandort">-->
  <!--<app-form-anlagenstandort [form]="form.get('anlagenstandort')"></app-form-anlagenstandort>-->
  <!--</mat-tab>-->
  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Steuerungstyp</mat-label>
      <input matInput formControlName="steuerungstyp" />
    </mat-form-field>
  </mat-tab>

  <!-- todo: wartungsbericht betriebsstundenerfassung-->
  <mat-tab label="Betriebsstunden">
    <h4>Bitte geben Sie die Betriebsstunden über die Checkliste ein.</h4>
  </mat-tab>

  <mat-tab label="Wartungsarbeiten" formGroupName="wartungsarbeiten">
    <app-form-select-pruefung formControlName="funktionskontrolleAggregate"
      >Funktionskontrolle Aggregate</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="funktionskontrolleSteuerung"
      >Funktionskontrolle Steuerung</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="pruefungBetriebsbuch"
      >Prüfung Betriebsbuch</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="kontrollUndAlarmeinrichtungen"
      >Kontroll- und Alarmeinrichtungen</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="pruefungZulauf"
      >Prüfung Zulauf</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="sichtkontrolleVkPuffer"
      >Sichtkontrolle Vk Puffer</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="sichtkontrolleReaktor"
      >Sichtkontrolle Reaktor</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="baulicherZustand"
      >Baulicher Zustand</app-form-select-pruefung
    >
    <app-form-ja-nein formControlName="wasserprobeGezogen">Wasserprobe gezogen</app-form-ja-nein>
    <h4>Reaktor</h4>

    <mat-form-field appearance="standard">
      <mat-label>Pruefung Sauerstoff (mg/L)</mat-label>
      <input matInput formControlName="pruefungSauerstoff" />
    </mat-form-field>
    <app-form-select-pruefung formControlName="pruefungLuftfilter"
      >Prüfung Luftfilter</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="pruefungLuftverteilung"
      >Prüfung Luftverteilung</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="pruefungMembranbeluefter"
      >Prüfung Membranbeluefter</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="schwimmschlammImReaktor"
      >Schwimmschlamm Im Reaktor</app-form-select-pruefung
    >
    <app-form-select-pruefung formControlName="schlammabtriebImAblauf"
      >Schlammabtrieb Im Ablauf</app-form-select-pruefung
    >
    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm In Reaktor1 (mL/L)</mat-label>
      <input matInput formControlName="belebtschlammInReaktor1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm In Reaktor2 (mL/L)</mat-label>
      <input matInput formControlName="belebtschlammInReaktor2" />
    </mat-form-field>
    <app-form-select-pruefung formControlName="veraenderungUeSAbzug"
      >Veränderung ÜS-Abzug</app-form-select-pruefung
    >
  </mat-tab>

  <mat-tab label="Messwerte" formGroupName="messwerte">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Letzte Abfuhr</mat-label>
      <input matInput formControlName="letzteAbfuhr" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen Vorklärung1 (%)</mat-label>
      <input matInput formControlName="schlammvolumenVK1" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen Vorklärung2 (%)</mat-label>
      <input matInput formControlName="schlammvolumenVK2" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen Vorklärung3 (%)</mat-label>
      <input matInput formControlName="schlammvolumenVK3" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>schlammabfuhr</mat-label>
      <input matInput formControlName="schlammabfuhr" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>pH-Wert Zulauf</mat-label>
      <input matInput formControlName="phWertZulauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>CSB Zulauf</mat-label>
      <input matInput formControlName="csbZulauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Leitfaehigkeit Zulauf</mat-label>
      <input matInput formControlName="leitfaehigkeit" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>pH-Wert Ablauf</mat-label>
      <input matInput formControlName="phWertAblauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>CSB Ablauf</mat-label>
      <input matInput formControlName="csbAblauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Leitfaehigkeit Ablauf</mat-label>
      <input matInput formControlName="leitfaehigkeitAblauf" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Absetzbare Stoffe (mL/L)</mat-label>
      <input matInput formControlName="absetzbareStoffe" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Temperatur (°C)</mat-label>
      <input matInput formControlName="temperatur" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Trübung</mat-label>
      <input matInput formControlName="truebung" />
    </mat-form-field>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Geruch</mat-label>
      <mat-select formControlName="geruch">
        <mat-option value="kein">kein</mat-option>
        <mat-option value="schwach">schwach</mat-option>
        <mat-option value="faulig">faulig</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Maßnahmen">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Maßnahmen</mat-label>
      <textarea
        matInput
        mat-autosize
        matAutosizeMinRows="6"
        formControlName="massnahmen"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Materialverbrauch">
    <app-form-materialverbrauch
      [form]="form.get('materialverbrauch')"
      [anlagenreihe]="anlagenreihe"
    ></app-form-materialverbrauch>
  </mat-tab>

  <mat-tab label="Zeit">
    <app-form-zeit [form]="form.get('zeit')"></app-form-zeit>
  </mat-tab>
</mat-tab-group>
