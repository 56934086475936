// @ts-ignore
import { version } from '../../package.json';

//api_url: 'https://kundendienst.lkt-luckau-service.de/apiMonteurApp.php',

export const environment = {
  api_url: 'https://www.rwl-service-portal.de/apiMonteurApp.php',
  production: true,
  sentry_dsn: '',
  version,
};
