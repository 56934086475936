import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormulareService } from '../../formulare/formulare.service';
import { Auftrag } from '../../state/auftrag.model';

@Component({
  selector: 'app-dialog-wartungsbericht-lkt-bioclear-vario',
  templateUrl: './dialog-wartungsbericht-lkt-bioclear-vario.component.html',
  styleUrls: ['./dialog-wartungsbericht-lkt-bioclear-vario.component.scss'],
})
export class DialogWartungsberichtLktBioclearVarioComponent implements OnInit {
  form: FormGroup;
  auftrag: Auftrag;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData, formulareService: FormulareService) {
    const { formData = {}, auftrag } = dialogData;

    if (!auftrag) {
      throw new Error('Kein Auftrag!');
    }

    this.auftrag = auftrag;

    this.form = formulareService.wartungsberichtBioClearVario(formData);

    this.form.patchValue(formData);
  }

  ngOnInit() {}
}
