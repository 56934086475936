import { Injectable } from '@angular/core';

import * as flat from 'flat';
import { Auftrag, createAuftrag } from '../state/auftrag.model';
import { HttpClient } from '@angular/common/http';
import { MeldungenService } from '../meldungen.service';
import { environment } from '../../environments/environment';
import { createTermin, Termin, Urlaubstermin, Zusatztermin } from '../state/termin.model';
import { TerminService } from '../state/termin.service';
import { AuftragQuery } from '../state/auftrag.query';
import { AuftragService } from '../state/auftrag.service';
import { FormularQuery } from '../state/formular.query';
import { FormularService } from '../state/formular.service';
import { createMaterial, Material } from '../state/material.model';
import { MaterialService } from '../state/material.service';

interface GetAuftragslisteResponse {
  auftraege: Auftrag[];
  zusatztermine: Zusatztermin[];
  urlaub_bereitschaft: Urlaubstermin[];
  materialliste: {
    ID: string;
    ARTIKELNUMMER: string;
    ARTIKELBEZEICHNUNG: string;
    EINHEIT: string;
  }[];
  materialzuordnunganlagenreihe: {
    ARTIKELID: string;
    ANLAGENREIHE: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private http: HttpClient,
    private formularQuery: FormularQuery,
    private formularService: FormularService,
    private auftragService: AuftragService,
    private materialService: MaterialService,
    private auftragQuery: AuftragQuery,
    private meldungen: MeldungenService,
    private terminService: TerminService
  ) {}

  async sync() {
    await this.formulareSynchronisieren();
    await this.auftraegeSynchronisieren();
  }

  async auftraegeSynchronisieren() {
    try {
      const result = await this.http
        .get<GetAuftragslisteResponse>(environment.api_url, {
          params: {
            funktion: 'getAuftragsliste',
          },
        })
        .toPromise();

      const { auftraege } = result;

      this.materialService.clear();
      this.materialService.add(getMaterialien(result));

      this.terminService.clear();
      this.terminService.add(getTermine(result));

      this.auftragService.clear();
      this.auftragService.add(auftraege.map(createAuftrag));
      const length = this.auftragQuery.getCount();

      this.meldungen.erfolg(
        length ? `${length} Aufträge heruntergeladen.` : 'Keine neuen Aufträge verfügbar.'
      );
    } catch (e) {
      throw e;
    }
  }

  async formulareSynchronisieren() {
    const abgeschlosseneIds = this.auftragQuery.getAbgeschlosseneIds();

    if (!abgeschlosseneIds.length) {
      return;
    }

    const forms = this.formularQuery.getAll({
      filterBy: (f) => abgeschlosseneIds.includes(f.auftragId),
    });

    const daten = forms.map((f) => {
      const form = flat(f.form, { delimiter: '.' });

      return {
        AID: f.auftragId,
        FORMULARNAME: f.typ,
        FORM: form,
      };
    });

    await this.http
      .post(environment.api_url, daten, {
        params: {
          funktion: 'speichereFormulardaten',
        },
      })
      .toPromise();
  }
}

function getTermine({
  auftraege,
  urlaub_bereitschaft,
  zusatztermine,
}: GetAuftragslisteResponse): Termin[] {
  return [
    ...auftraege.map((auftrag) => ({
      start: auftrag.EINSATZTERMIN,
      title: auftrag.TITEL,
      meta: {
        auftrag_id: auftrag.AID,
      },
    })),
    ...zusatztermine.map((zusatztermin) => ({
      start: zusatztermin.ZEITRAUMVON,
      end: zusatztermin.ZEITRAUMBIS,
      title: zusatztermin.INFOEINTRAGKURZ,
      meta: {
        zusatztermin,
      },
    })),
    ...urlaub_bereitschaft.map((urlaubstermin) => ({
      start: urlaubstermin.TAGSTART,
      title: urlaubstermin.KENNZEICHEN,
      allDay: true,
      meta: {
        urlaubstermin,
      },
    })),
  ].map(createTermin);
}

function getMaterialien({
  materialliste,
  materialzuordnunganlagenreihe,
}: GetAuftragslisteResponse): Material[] {
  return materialliste
    .map((m) => ({
      id: m.ID,
      artikelbezeichnung: m.ARTIKELBEZEICHNUNG,
      artikelnummer: m.ARTIKELNUMMER,
      einheit: m.EINHEIT,
      anlagenreihen: materialzuordnunganlagenreihe
        .filter((mz) => mz.ARTIKELID === m.ID)
        .map((mz) => mz.ANLAGENREIHE),
    }))
    .map(createMaterial);
}
