import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

export function newId() {
  return Math.random().toString(16).substring(2).padStart(14, '0');
}

export function countdown(seconds: number): Observable<number> {
  return timer(0, 1000).pipe(
    take(seconds + 1),
    map((value, index) => seconds - index)
  );
}
