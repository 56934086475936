<mat-tab-group [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Steuerungstyp</mat-label>
      <input matInput formControlName="steuerungstyp" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Wartungsarbeiten" formGroupName="wartungsarbeiten">
    <app-form-select-pruefung formControlName="baulicherZustand">
      Baulicher Zustand
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pruefungKoaleszenzeinsatz">
      Prüfung Koaleszenzeinsatz
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pruefungSchwimmerVerschluss">
      Prüfung Schwimmer Verschluss
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="funktionWarnanlage">
      Funktion Warnanlage
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pruefungAblauf">
      Prüfung Ablauf
    </app-form-select-pruefung>
    <app-form-select-pruefung formControlName="pruefungBetriebsbuch">
      Prüfung Betriebsbuch
    </app-form-select-pruefung>
    <app-form-ja-nein formControlName="wasserprobeGezogen">
      Wasserprobe gezogen
    </app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Messungen" formGroupName="messungen">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Temperatur (°C)</mat-label>
      <input matInput formControlName="temperatur" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>pH-Wert</mat-label>
      <input matInput formControlName="phWert" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Schlammvolumen im Schlammfang</mat-label>
      <input matInput formControlName="schlammvolumenImSchlammfang" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Entsorgung Schlamm</mat-label>
      <input matInput formControlName="entsorgungSchlamm" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Speichervolumen Fett (%)</mat-label>
      <input matInput formControlName="speichervolumenFett" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Speichervolumen Leichtflüssigkeit</mat-label>
      <input matInput formControlName="speichervolumenLeichtfluessigkeit" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Entsorgung Fett oder Leichtflüssigkeit</mat-label>
      <input matInput formControlName="entsorgungFettOderLeichtfluessigkeit" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Maßnahmen">
    <mat-form-field class="w-100" appearance="standard">
      <mat-label>Maßnahmen</mat-label>
      <textarea
        matInput
        mat-autosize
        matAutosizeMinRows="6"
        formControlName="massnahmen"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Materialverbrauch">
    <app-form-materialverbrauch
      [form]="form.get('materialverbrauch')"
      [anlagenreihe]="anlagenreihe"
    ></app-form-materialverbrauch>
  </mat-tab>

  <mat-tab label="Zeit">
    <app-form-zeit [form]="form.get('zeit')"></app-form-zeit>
  </mat-tab>
</mat-tab-group>
