import { newId } from '../util';

export interface Formular {
  id: string;
  auftragId: string;
  typ: string;
  form: any;
}

export function createFormular(params: Partial<Formular>) {
  return {
    id: newId(),
    ...params,
  } as Formular;
}
