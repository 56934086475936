import { Component } from '@angular/core';
import { SyncService } from '../../services/sync.service';
import { environment } from '../../../environments/environment';
import { DialogeService } from '../../dialoge/dialoge.service';
import { Router } from '@angular/router';
import { MeldungenService } from '../../meldungen.service';
import { AuthService } from '../../auth/state';
import { StateService } from '../../state/state.service';

@Component({
  selector: 'app-main',
  templateUrl: './view-main.component.html',
  styleUrls: ['./view-main.component.scss'],
})
export class ViewMainComponent {
  readonly version = environment.version;

  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches)
  //   );

  constructor(
    private syncService: SyncService,
    private dialogeService: DialogeService,
    private router: Router,
    private meldungen: MeldungenService,
    private authService: AuthService,
    private stateService: StateService
  ) {}

  async sync() {
    await this.syncService.sync();
    this.router.navigateByUrl('/');
  }

  infoAnzeigen() {
    this.dialogeService.info();
  }

  benutzerAbmelden() {
    this.authService.logout();
  }

  async datenLoeschen() {
    if (
      !window.confirm(
        'Wirklich alle Daten löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.'
      )
    ) {
      return;
    }

    this.stateService.wipe();
    this.meldungen.erfolg('Alle Daten gelöscht.');
    this.router.navigateByUrl('/');
  }
}
