import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Termin } from './termin.model';

export interface TerminState extends EntityState<Termin> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'termin', resettable: true })
export class TerminStore extends EntityStore<TerminState> {
  constructor() {
    super();
  }
}
