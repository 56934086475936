<mat-tab-group [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field appearance="standard">
      <mat-label>Anzahl Personen</mat-label>
      <input matInput formControlName="anzahlPersonen" type="number" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Anzeige im Display</mat-label>
      <textarea
        matInput
        mat-autosize
        matAutosizeMinRows="4"
        formControlName="anzeigeImDisplay"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Laufzeiten" formGroupName="laufzeiten">
    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P1 ein Ist (min)
          </mat-label>
          <input matInput formControlName="pumpeP1EinIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P1 aus Ist (min)
          </mat-label>
          <input matInput formControlName="pumpeP1AusIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P2 ein Ist (min)
          </mat-label>
          <input matInput formControlName="pumpeP2EinIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P2 aus Ist (min)
          </mat-label>
          <input matInput formControlName="pumpeP2AusIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P3 ein Ist (min)
          </mat-label>
          <input matInput formControlName="pumpeP3EinIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P3 aus Ist (min)
          </mat-label>
          <input matInput formControlName="pumpeP3AusIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Nachtlaufzeit P1 Ist (min)
          </mat-label>
          <input matInput formControlName="nachtlaufzeitP1Ist" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Puffer P4 ein Ist (min)
          </mat-label>
          <input matInput formControlName="pufferP4EinIst" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Puffer P4 aus Ist (min)
          </mat-label>
          <input matInput formControlName="pufferP4AusIst" type="number" />
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P1 ein Veränderung (min)
          </mat-label>
          <input matInput formControlName="pumpeP1EinVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P1 aus Veränderung (min)
          </mat-label>
          <input matInput formControlName="pumpeP1AusVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P2 ein Veränderung (min)
          </mat-label>
          <input matInput formControlName="pumpeP2EinVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P2 aus Veränderung (min)
          </mat-label>
          <input matInput formControlName="pumpeP2AusVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P3 ein Veränderung (min)
          </mat-label>
          <input matInput formControlName="pumpeP3EinVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Pumpe P3 aus Veränderung (min)
          </mat-label>
          <input matInput formControlName="pumpeP3AusVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Nachtlaufzeit P1 Veränderung (min)
          </mat-label>
          <input matInput formControlName="nachtlaufzeitP1Veraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Puffer P4 ein Veränderung (min)
          </mat-label>
          <input matInput formControlName="pufferP4EinVeraenderung" type="number" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>
            Puffer P4 aus Veränderung (min)
          </mat-label>
          <input matInput formControlName="pufferP4AusVeraenderung" type="number" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Betriebsstunden" formGroupName="betriebsstunden">
    <mat-form-field appearance="standard">
      <mat-label>
        Pumpe P1
      </mat-label>
      <input matInput formControlName="pumpeP1" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Pumpe P2
      </mat-label>
      <input matInput formControlName="pumpeP2" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Pumpe P3
      </mat-label>
      <input matInput formControlName="pumpeP3" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Puffer
      </mat-label>
      <input matInput formControlName="puffer" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        hp1h
      </mat-label>
      <input matInput formControlName="hp1h" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        hp1 ein
      </mat-label>
      <input matInput formControlName="hp1Ein" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        hp1 aus
      </mat-label>
      <input matInput formControlName="hp1Aus" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        hp2h
      </mat-label>
      <input matInput formControlName="hp2h" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        hp2 ein
      </mat-label>
      <input matInput formControlName="hp2Ein" type="number" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>
        hp2 aus
      </mat-label>
      <input matInput formControlName="hp2Aus" type="number" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Schnelltest" formGroupName="schnelltest">
    <app-form-ja-nein formControlName="pumpeP1OK">Pumpe P1 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpeP2OK">Pumpe P2 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpeP3OK">Pumpe P3 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="funktionSchw1Und2OK"
      >Funktion Schwimmer 1 und 2 OK</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="pufferSpruehtellerOK"
      >Puffer Sprühteller OK</app-form-ja-nein
    >
  </mat-tab>

  <mat-tab label="Sichtkontrolle" formGroupName="sichtkontrolle">
    <app-form-ja-nein formControlName="schwimmschlammInK3DerVK"
      >Schwimmschlamm in K3 der VK</app-form-ja-nein
    >
    <mat-form-field appearance="standard">
      <mat-label>
        Schwimmschlamm im Puffer (cm)
      </mat-label>
      <input matInput formControlName="schwimmschlammImPuffer" type="number" />
    </mat-form-field>
    <app-form-ja-nein formControlName="pfuetzenbildungAufDerLavaschlacke"
      >Pfützenbildung auf der Lavaschlacke</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="ablagerungenAufDerLavaschlacke"
      >Ablagerungen auf der Lavaschlacke</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="verteilungUeberDemSpruehteller"
      >Verteilung über dem Sprühteller</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="speicherraumNichtUeberstaut"
      >Speicherraum nicht Überstaut</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="schwimmschlammInDerNK"
      >Schwimmschlamm in der NK</app-form-ja-nein
    >

    <mat-form-field appearance="standard">
      <mat-label>
        Temperatur der Klarwasserprobe (°C)
      </mat-label>
      <input matInput formControlName="temperaturDerKlarwasserprobe" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        pH-Wert der Klarwasserprobe
      </mat-label>
      <input matInput formControlName="pHWertDerKlarwasserprobe" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Absetzbare Stoffe in der Klarwasserprobe
      </mat-label>
      <input matInput formControlName="absetzbareStoffeDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        CSB
      </mat-label>
      <input matInput formControlName="CSB" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        BSB5
      </mat-label>
      <input matInput formControlName="BSB5" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label> O<sub>2</sub> Klarwasserprobe </mat-label>
      <input matInput formControlName="O2Klarwasserprobe" />
    </mat-form-field>

    <app-form-ja-nein formControlName="beUndEntlueftungOK">Be- und Entlüftung OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="spueloeffnungenP2">Spülöffnungen P2</app-form-ja-nein>

    <mat-form-field appearance="standard">
      <mat-label>
        Dickstoffe in VK K1 (%)
      </mat-label>
      <input matInput formControlName="dickstoffeInVKInProzentK1" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Dickstoffe in VK K2 (%)
      </mat-label>
      <input matInput formControlName="dickstoffeInVKInProzentK2" type="number" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Dickstoffe in VK K3 (%)
      </mat-label>
      <input matInput formControlName="dickstoffeInVKInProzentK3" type="number" />
    </mat-form-field>

    <app-form-ja-nein formControlName="schlammabfuhr">Schlammabfuhr</app-form-ja-nein>

    <mat-form-field appearance="standard">
      <mat-label>
        Menge Schwimmschlamm im Puffer
      </mat-label>
      <input matInput formControlName="mengeSchwimmschlammImPuffer" type="number" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Bemerkungen" formGroupName="bemerkungen">
    <mat-form-field appearance="standard">
      <mat-label>
        Störungsmeldungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="stoerungsmeldungen"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Bemerkungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="bemerkungen"
      ></textarea>
    </mat-form-field>
  </mat-tab>
</mat-tab-group>
