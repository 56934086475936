import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { parseISO, toDate } from 'date-fns';

@Pipe({
  name: 'gracefulDate',
})
export class GracefulDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    const date = parse(value);

    return super.transform(date, format, timezone, locale);
  }
}

function parse(dirty: any): Date {
  if (typeof dirty === 'string') {
    return parseISO(dirty);
  } else {
    return toDate(dirty);
  }
}
