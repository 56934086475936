<ng-container [formGroup]="form">
  <h3>Anreise</h3>
  <ng-container formGroupName="anreise">
    <mat-form-field appearance="standard">
      <mat-label>Beginn</mat-label>
      <input matInput formControlName="start" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Ende</mat-label>
      <input matInput formControlName="ende" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Entfernung (km)</mat-label>
      <input matInput type="number" formControlName="entfernung" />
    </mat-form-field>
  </ng-container>

  <h3>Arbeitszeit</h3>
  <ng-container formGroupName="arbeit">
    <mat-form-field appearance="standard">
      <mat-label>Beginn</mat-label>
      <input matInput formControlName="start" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Ende</mat-label>
      <input matInput formControlName="ende" />
    </mat-form-field>
  </ng-container>

  <h3>Abreise</h3>
  <ng-container formGroupName="abreise">
    <mat-form-field appearance="standard">
      <mat-label>Beginn</mat-label>
      <input matInput formControlName="start" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Ende</mat-label>
      <input matInput formControlName="ende" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Entfernung (km)</mat-label>
      <input matInput type="number" formControlName="entfernung" />
    </mat-form-field>
  </ng-container>
</ng-container>
