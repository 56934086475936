<h1 mat-dialog-title>Info</h1>
<div mat-dialog-content>
  <h3>App</h3>
  <p>Version: {{ version }}</p>
  <br />
  <h3>Speicher</h3>
  <p>
    verfügbar: {{ quota | bytes: 2 }} <br />
    belegt: {{ usage | bytes: 2 }} ({{ usage / quota | percent }})
  </p>
  <br />
  <h3>Browser</h3>
  <p>
    Name: {{ browser.name | titlecase }} <br />
    Version: {{ browser.version }} <br />
    Plattform: {{ browser.os }}
  </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>
</div>
