import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-checkliste-lkt-bioflow',
  templateUrl: './form-checkliste-bio-flow.component.html',
  styleUrls: ['./form-checkliste-bio-flow.component.scss', '../checklisten.scss'],
})
export class FormChecklisteBioFlowComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit() {}
}
