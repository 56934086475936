import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ViewMainComponent } from './views/view-main/view-main.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ViewUebersichtComponent } from './views/view-uebersicht/view-uebersicht.component';
import { ViewAuftraegeComponent } from './views/view-auftraege/view-auftraege.component';
import { ViewAuftragComponent } from './views/view-auftrag/view-auftrag.component';

import '@angular/common/locales/global/de';

import { AuftragComponent } from './components/auftrag/auftrag.component';
import { errorhandlerProvider } from './services/my-errorhandler.service';
import { SharedModule } from './shared/shared.module';
import { DialogeModule } from './dialoge/dialoge.module';
import { AuthModule } from './auth/auth.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuftragCardComponent } from './components/auftrag-card/auftrag-card.component';
import { ZusatzterminCardComponent } from './components/zusatztermin-card/zusatztermin-card.component';
import { UrlaubsterminCardComponent } from './components/urlaubstermin-card/urlaubstermin-card.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AppUpdateService } from './services/app-update.service';
import { UpdateAvailableSnackbarComponent } from './components/update-available-snackbar/update-available-snackbar.component';
import { RecoveryService } from './recovery.service';

@NgModule({
  declarations: [
    AppComponent,

    ViewMainComponent,
    ViewUebersichtComponent,
    ViewAuftraegeComponent,
    ViewAuftragComponent,
    AuftragComponent,
    AuftragCardComponent,
    ZusatzterminCardComponent,
    UrlaubsterminCardComponent,
    UpdateAvailableSnackbarComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,

    DialogeModule,
    LayoutModule,

    AuthModule,

    AppRoutingModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'de' }, errorhandlerProvider],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private serviceWorkerService: AppUpdateService, private recovery: RecoveryService) {}
}
