import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MeldungenService {
  constructor(private snackbar: MatSnackBar) {}

  erfolg(text: string) {
    this.snackbar.open(text, null, {
      duration: 2000,
    });
  }
}
