import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { TerminStore, TerminState } from './termin.store';

@Injectable({ providedIn: 'root' })
export class TerminQuery extends QueryEntity<TerminState> {
  constructor(protected store: TerminStore) {
    super(store);
  }

  selectAlle() {
    return this.selectAll({
      sortBy: 'start',
      sortByOrder: Order.ASC,
    });
  }
}
