<mat-accordion multi="true">
  <mat-expansion-panel *ngFor="let tag of tage | async">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tag.datum | gracefulDate: 'fullDate' }}
      </mat-panel-title>
      <mat-panel-description> {{ tag.termine.length }} Termine </mat-panel-description>
    </mat-expansion-panel-header>

    <div *matExpansionPanelContent class="panel-content">
      <ng-container *ngFor="let termin of tag.termine">
        <app-urlaubstermin-card
          *ngIf="termin.meta.urlaubstermin"
          [urlaubstermin]="termin.meta.urlaubstermin"
        ></app-urlaubstermin-card>
      </ng-container>

      <ng-container *ngFor="let termin of tag.termine">
        <app-auftrag-card
          *ngIf="termin.meta.auftrag_id"
          [auftrag]="selectAuftrag(termin.meta.auftrag_id) | async"
        ></app-auftrag-card>
        <app-zusatztermin-card
          *ngIf="termin.meta.zusatztermin"
          [zusatztermin]="termin.meta.zusatztermin"
        ></app-zusatztermin-card>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
