import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogChecklisteLktBioflowComponent } from './dialog-checkliste-lkt-bioflow/dialog-checkliste-lkt-bioflow.component';
import { DialogUnterschriftComponent } from './dialog-unterschrift/dialog-unterschrift.component';
import { DialogChecklisteLktBioairComponent } from './dialog-checkliste-lkt-bioair/dialog-checkliste-lkt-bioair.component';
import { DialogChecklisteLktBioclearComponent } from './dialog-checkliste-lkt-bioclear/dialog-checkliste-lkt-bioclear.component';
import { DialogChecklisteLktBioclearVarioComponent } from './dialog-checkliste-lkt-bioclear-vario/dialog-checkliste-lkt-bioclear-vario.component';
import { DialogChecklisteLktBiovarioComponent } from './dialog-checkliste-lkt-biovario/dialog-checkliste-lkt-biovario.component';
import { DialogWartungsberichtAbscheiderComponent } from './dialog-wartungsbericht-abscheider/dialog-wartungsbericht-abscheider.component';
import { DialogWartungsberichtKlaeranlageComponent } from './dialog-wartungsbericht-klaeranlage/dialog-wartungsbericht-klaeranlage.component';
import { DialogWartungsberichtLktBioclearVarioComponent } from './dialog-wartungsbericht-lkt-bioclear-vario/dialog-wartungsbericht-lkt-bioclear-vario.component';
import { DialogWartungsberichtPumpstationenComponent } from './dialog-wartungsbericht-pumpstationen/dialog-wartungsbericht-pumpstationen.component';
import { DialogArbeitsberichtComponent } from './dialog-arbeitsbericht/dialog-arbeitsbericht.component';
import { DialogeModule } from './dialoge.module';
import { DialogBestaetigungComponent } from './dialog-bestaetigung/dialog-bestaetigung.component';
import { Auftrag } from '../state/auftrag.model';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { DialogChecklisteLktBioLogoComponent } from './dialog-checkliste-lkt-biologo/dialog-checkliste-lkt-biologo.component';

const defaultHeight = '90vh';
const defaultWidth = '90vw';

const defaultOptions = {
  hasBackdrop: true,
  disableClose: true,
  minHeight: defaultHeight,
  maxHeight: defaultHeight,
  minWidth: defaultWidth,
  maxWidth: defaultWidth,
  panelClass: 'dialog-flex',
};

@Injectable({
  providedIn: DialogeModule,
})
export class DialogeService {
  constructor(private dialog: MatDialog) {}

  async bestaetigung(data: any) {
    // todo typ festlegen
    const dRef = this.dialog.open(DialogBestaetigungComponent, {
      data,
    });

    return dRef.afterClosed().toPromise();
  }

  async arbeitsbericht(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogArbeitsberichtComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async wartungsberichtAbscheider(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogWartungsberichtAbscheiderComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async wartungsberichtKlaeranlage(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogWartungsberichtKlaeranlageComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async wartungsberichtLktBioclearVario(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogWartungsberichtLktBioclearVarioComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async wartungsberichtPumpstationen(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogWartungsberichtPumpstationenComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async checklisteBioAir(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogChecklisteLktBioairComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async checklisteBioClear(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogChecklisteLktBioclearComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async checklisteBioClearVario(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogChecklisteLktBioclearVarioComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async checklisteBioFlow(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogChecklisteLktBioflowComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async checklisteBioVario(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogChecklisteLktBiovarioComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }
  async checklisteBioLogo(formData = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogChecklisteLktBioLogoComponent, {
      ...defaultOptions,
      data: {
        auftrag,
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async unterschrift(formData: any = {}, auftrag: Auftrag) {
    const dRef = this.dialog.open(DialogUnterschriftComponent, {
      ...defaultOptions,
      data: {
        formData,
      },
    });

    return dRef.afterClosed().toPromise();
  }

  async info() {
    this.dialog.open(DialogInfoComponent, {
      minWidth: '30vw',
    });
  }
}
