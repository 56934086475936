import { Injectable } from '@angular/core';

import { set, get, clear } from 'idb-keyval';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

const STORAGE_KEY = 'lkt-monteur-app';
const RECOVERY_KEY = 'lkt-monteur-app-recovery-done';

@Injectable({
  providedIn: 'root',
})
export class RecoveryService {
  constructor(private httpClient: HttpClient) {
    // this.run();
  }

  async run() {
    if (await get(RECOVERY_KEY)) {
      return;
    }

    const data = await get(STORAGE_KEY);

    if (!data) {
      return;
    }

    await this.httpClient
      .get<any>(environment.api_url, {
        params: {
          funktion: 'pruefeSession',
        },
      })
      .toPromise();

    try {
      const result = await this.httpClient
        .post<any>(environment.api_url, data, {
          params: {
            funktion: 'recovery',
          },
        })
        .toPromise();

      if (result && result.error === null) {
        await set(RECOVERY_KEY, { uebertragen: new Date().toISOString() });
        alert(
          'Auf dem Tablet vorhandene Formular-Daten wurden zur Wiederherstellung an den Server gesendet. Vielen Dank.'
        );
      }
    } catch (e) {
      alert(
        'Auf dem Tablet vorhandene Formular-Daten sollten zur Wiederherstellung an den Server gesendet werden. Dabei ist ein Fehler aufgetreten. Bitte erstellen Sie nach Möglichkeit einen Screenshot für die Fehlerbeseitigung.\n\nFehlermeldung: \n' +
          e.message || e.toString()
      );
    }
  }
}
