import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewMainComponent } from './views/view-main/view-main.component';
import { ViewAuftraegeComponent } from './views/view-auftraege/view-auftraege.component';
import { ViewAuftragComponent } from './views/view-auftrag/view-auftrag.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full',
      },
      {
        path: 'app',
        component: ViewMainComponent,
        canActivate: [],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'auftraege',
          },
          {
            path: 'auftraege',
            children: [
              {
                path: '',
                component: ViewAuftraegeComponent,
              },
              {
                path: ':auftragId',
                component: ViewAuftragComponent,
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
