export interface Material {
  id: string;
  artikelnummer: string;
  artikelbezeichnung: string;
  einheit: string;
  anlagenreihen: string[];
}

export function createMaterial(params: Partial<Material>) {
  return {
    id: params.id,
    artikelnummer: params.artikelnummer,
    artikelbezeichnung: params.artikelbezeichnung,
    einheit: params.einheit,
    anlagenreihen: params.anlagenreihen,
  } as Material;
}
