<mat-tab-group [formGroup]="form">
  <mat-tab label="Daten" formGroupName="daten">
    <mat-form-field appearance="standard">
      <mat-label>Anzahl Personen</mat-label>
      <input matInput type="number" formControlName="anzahlPersonen" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>
        Anzeige im Display
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="anzeigeImDisplay"
      ></textarea>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Laufzeiten" formGroupName="laufzeiten">
    <div fxLayout="row" fxLayoutGap="1em">
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Belüfter B1 ein Ist</mat-label>
          <input matInput type="number" formControlName="beluefterB1EinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar ein Ist</mat-label>
          <input matInput type="number" formControlName="sparEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüfter B1 aus Ist</mat-label>
          <input matInput type="number" formControlName="beluefterB1AusIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüfter spar aus Ist</mat-label>
          <input matInput type="number" formControlName="beluefterSparAusIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>n min Ist</mat-label
          ><!-- todo was ist n?-->
          <input matInput type="number" formControlName="nMinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>s min Ist</mat-label>
          <input matInput type="number" formControlName="sminIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Beschickung ein Ist</mat-label>
          <input matInput type="number" formControlName="beschickungEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammrückführung ein Ist</mat-label>
          <input matInput type="number" formControlName="schlammrueckfuehrungEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasserpumpe ein Ist</mat-label>
          <input matInput type="number" formControlName="klarwasserpumpeEinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Sprühzeit 1 ein Ist</mat-label>
          <input matInput type="number" formControlName="spruehzeit1EinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Sprühzeit 2 ein Ist</mat-label>
          <input matInput type="number" formControlName="spruehzeit2EinIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pumpe 4 Ist</mat-label>
          <input matInput type="number" formControlName="pumpe4Ist" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Max. Druck Ist</mat-label>
          <input matInput type="number" formControlName="maxDruckIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Arbeitsdruck Ist</mat-label>
          <input matInput type="number" formControlName="arbeitsdruckIst" />
        </mat-form-field>
        <h3>Denitrifikation</h3>

        <mat-form-field appearance="standard">
          <mat-label>Normal Ein Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinNormalIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Aus Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusNormalIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Dauer Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerNormalIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Ein Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinSparIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Aus Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusSparIst" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Dauer Ist</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerSparIst" />
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <mat-form-field appearance="standard">
          <mat-label>Belüfter B1 ein Veränderung</mat-label>
          <input matInput type="number" formControlName="beluefterB1EinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar ein Veränderung</mat-label>
          <input matInput type="number" formControlName="sparEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüfter B1 Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="beluefterB1AusVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Belüfter Spar Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="beluefterSparAusVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>n min Veränderung</mat-label>
          <input matInput type="number" formControlName="nMinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>s min Veränderung</mat-label>
          <input matInput type="number" formControlName="sminVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Beschickung ein Veränderung</mat-label>
          <input matInput type="number" formControlName="beschickungEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Schlammrückführung ein Veränderung</mat-label>
          <input matInput type="number" formControlName="schlammrueckfuehrungEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Klarwasserpumpe ein Veränderung</mat-label>
          <input matInput type="number" formControlName="klarwasserpumpeEinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Sprühzeit 1 ein Veränderung</mat-label>
          <input matInput type="number" formControlName="spruehzeit1EinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Sprühzeit 2 ein Veränderung</mat-label>
          <input matInput type="number" formControlName="spruehzeit2EinVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Pumpe 4 Veränderung</mat-label>
          <input matInput type="number" formControlName="pumpe4Veraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Max. Druck Veränderung</mat-label>
          <input matInput type="number" formControlName="maxDruckVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Arbeitsdruck Veränderung</mat-label>
          <input matInput type="number" formControlName="arbeitsdruckVeraenderung" />
        </mat-form-field>
        <h3>&nbsp;</h3>

        <mat-form-field appearance="standard">
          <mat-label>Normal Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinNormalVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusNormalVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Normal Dauer Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerNormalVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Ein Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationEinSparVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Aus Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationAusSparVeraenderung" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Spar Dauer Veränderung</mat-label>
          <input matInput type="number" formControlName="denitrifikationDauerSparVeraenderung" />
        </mat-form-field>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Betriebsstunden" formGroupName="betriebsstunden">
    <mat-form-field appearance="standard">
      <mat-label>Belüfter B1</mat-label>
      <input matInput type="number" formControlName="beluefterB1" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Pumpe 2</mat-label>
      <input matInput type="number" formControlName="pumpe2" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Pumpe 3</mat-label>
      <input matInput type="number" formControlName="pumpe3" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Pumpe 4</mat-label>
      <input matInput type="number" formControlName="pumpe4" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Belüfter B1 Test (A)</mat-label>
      <input matInput type="number" formControlName="beluefterB1TestAmpere" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Pumpe 2 Test (A)</mat-label>
      <input matInput type="number" formControlName="pumpe2TestAmpere" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Pumpe 3 Test (A)</mat-label>
      <input matInput type="number" formControlName="pumpe3TestAmpere" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Strom Pumpe 4 Test (A)</mat-label>
      <input matInput type="number" formControlName="pumpe4TestAmpere" />
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Schnelltest" formGroupName="schnelltest">
    <app-form-ja-nein formControlName="beluefterB1OK">Belüfter B1 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpe2OK">Pumpe 2 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpe3OK">Pumpe 3 OK</app-form-ja-nein>
    <app-form-ja-nein formControlName="pumpe4OK">Pumpe 4 OK</app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Sichtkontrolle" formGroupName="sichtkontrolle">
    <mat-form-field appearance="standard">
      <mat-label>Färbung der Biologie</mat-label>
      <input matInput type="text" formControlName="faerbungDerBiologie" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Belebtschlamm im Reaktor</mat-label>
      <input matInput type="number" formControlName="belebtschlammImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Schwimmschlamm im Reaktor</mat-label>
      <input matInput type="number" formControlName="schwimmschlammImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration im Reaktor</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Temperatur im Reaktor</mat-label>
      <input matInput type="number" formControlName="temperaturImReaktor" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>pH-Wert der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="pHWertDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        CSB
      </mat-label>
      <input matInput formControlName="CSB" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        BSB5
      </mat-label>
      <input matInput formControlName="BSB5" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Temperatur der Klarwasserprobe (°C)</mat-label>
      <input matInput type="number" formControlName="temperaturDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>O<sub>2</sub>-Konzentration der Klarwasserprobe</mat-label>
      <input matInput type="number" formControlName="o2KonzentrationDerKlarwasserprobe" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Absetzbare Stoffe der Klarwasserprobe</mat-label>
      <input matInput formControlName="absetzbareStoffeDerKlarwasserprobe" />
    </mat-form-field>

    <app-form-ja-nein formControlName="auslaufAusDerKlaeranlage"
      >Auslauf aus der Kläranlage</app-form-ja-nein
    >
    <app-form-ja-nein formControlName="zulaufUeberstaut">Zulauf Überstaut</app-form-ja-nein>
    <app-form-ja-nein formControlName="beUndEntlueftungOK">Be- und Entlüftung OK</app-form-ja-nein>
    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK1 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK1" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK2 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK2" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Dickstoffe in VK3 (%)</mat-label>
      <input matInput type="number" formControlName="dickstoffeInVKInProzentK3" />
    </mat-form-field>

    <app-form-ja-nein formControlName="schlammabfuhrOK">Schlammabfuhr OK</app-form-ja-nein>
  </mat-tab>

  <mat-tab label="Bemerkungen" formGroupName="bemerkungen">
    <mat-form-field appearance="standard">
      <mat-label>
        Störungsmeldungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="stoerungsmeldungen"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>
        Bemerkungen
      </mat-label>
      <textarea
        mat-autosize
        matInput
        matAutosizeMinRows="4"
        formControlName="bemerkungen"
      ></textarea>
    </mat-form-field>
  </mat-tab>
</mat-tab-group>
