import * as Sentry from '@sentry/browser';
import { ErrorHandler, forwardRef, Provider, Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthQuery } from '../auth/state';
import { Scope } from '@sentry/types';

export const errorhandlerProvider: Provider = {
  provide: ErrorHandler,
  useClass: forwardRef(() => SentryErrorHandler),
};

const useSentry = !!environment.sentry_dsn;

@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();

    if (useSentry) {
      Sentry.init({
        dsn: environment.sentry_dsn,
        release: environment.version,
        integrations: [
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          }),
        ],
      });
    }
  }

  handleError(err: any): void {
    if (useSentry) {
      const extractedError = extractError(err) || 'Handled unknown error';

      Sentry.captureException(extractedError, (scope) => this.getErrorScope(scope));
    }

    super.handleError(err);
  }

  private getErrorScope(scope: Scope): Scope {
    const session = this.getSession();

    if (session) {
      scope.setExtra('session', session);
    }

    return scope;
  }

  private getSession(): string | null {
    const authQuery = this.injector.get(AuthQuery);

    return authQuery?.getSession();
  }
}

function extractError(error) {
  // Try to unwrap zone.js error.
  // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
  if (error && error.ngOriginalError) {
    error = error.ngOriginalError;
  }

  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || error instanceof Error) {
    return error;
  }

  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (error.error instanceof Error) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent) {
      return error.error.message;
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
  }

  // Skip if there's no error, and let user decide what to do with it.
  return null;
}
