import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { Termin } from './termin.model';
import { TerminStore } from './termin.store';

@Injectable({ providedIn: 'root' })
export class TerminService {
  constructor(private terminStore: TerminStore, private http: HttpClient) {}

  get() {
    return this.http.get<Termin[]>('https://api.com').pipe(
      tap((entities) => {
        this.terminStore.set(entities);
      })
    );
  }

  add(termin: Termin | Termin[]) {
    this.terminStore.add(termin);
  }

  update(id, termin: Partial<Termin>) {
    this.terminStore.update(id, termin);
  }

  remove(id: ID) {
    this.terminStore.remove(id);
  }

  clear() {
    this.terminStore.reset();
  }
}
