import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Formular } from './formular.model';
import { FormularStore } from './formular.store';

@Injectable({ providedIn: 'root' })
export class FormularService {
  constructor(private formularStore: FormularStore) {}

  add(formular: Formular) {
    this.formularStore.add(formular);
  }

  update(id, formular: Partial<Formular>) {
    this.formularStore.update(id, formular);
  }

  remove(id: ID) {
    this.formularStore.remove(id);
  }
}
