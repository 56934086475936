import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Urlaubstermin } from '../../state/termin.model';

@Component({
  selector: 'app-urlaubstermin-card',
  templateUrl: './urlaubstermin-card.component.html',
  styleUrls: ['./urlaubstermin-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlaubsterminCardComponent implements OnInit {
  @Input() urlaubstermin: Urlaubstermin;

  readonly farben = {
    krank: 'rgba(173,33,33,.8)',
    urlaub: 'rgba(30,144,255,.8)',
    bereitschaft: 'rgba(227,188,8,.8)',
  };

  constructor() {}

  ngOnInit() {}
}
