<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center">
  <h1 fxFlex style="margin: 0;">Unterschrift</h1>
  <mat-checkbox [formControl]="keinUnterschriftsberechtigterVorOrt"
    >Kein Unterschriftberechtiger vor Ort</mat-checkbox
  >
</div>

<div mat-dialog-content fxFlex fxLayout="column">
  <canvas #pad="signaturePad" signaturePad fxFlex></canvas>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="pad.signaturePad.clear()">Korrektur</button>
  <span fxFlex></span>
  <button mat-button [mat-dialog-close]>Abbrechen</button>
  <button mat-button (click)="ok()" cdkFocusInitial>OK</button>
</div>
