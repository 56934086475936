<h1 mat-dialog-title>Checkliste BIOlogo</h1>
<div mat-dialog-content>
  <app-form-checkliste-lkt-biologo [form]="form"></app-form-checkliste-lkt-biologo>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Abbrechen</button>
  <button mat-button [mat-dialog-close]="form.dirty ? form.value : null" cdkFocusInitial>
    Speichern
  </button>
</div>
