import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignaturePadDirective } from '../../signature-pad/signature-pad.directive';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dialog-unterschrift',
  templateUrl: './dialog-unterschrift.component.html',
  styleUrls: ['./dialog-unterschrift.component.scss'],
})
export class DialogUnterschriftComponent implements OnInit, AfterViewInit {
  data: any;

  keinUnterschriftsberechtigterVorOrt = new FormControl(false);

  @ViewChild('pad', { static: true }) pad: SignaturePadDirective;

  constructor(
    private dialogRef: MatDialogRef<DialogUnterschriftComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any = {}
  ) {
    this.data = {
      format: 'image/jpeg',
      ...data,
    };
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.data.formData.url) {
      this.pad.queuedDataURL = this.data.formData.url;
    }
  }

  ok() {
    const url = this.pad.signaturePad.toDataURL(this.data.format, this.data.quality);

    this.dialogRef.close({
      keinUnterschriftsberechtigterVorOrt: this.keinUnterschriftsberechtigterVorOrt.value,
      url,
    });
  }
}
