<h1 mat-dialog-title>Checkliste BIOair</h1>
<div mat-dialog-content>
  <app-form-checkliste-lkt-bioair [form]="form"></app-form-checkliste-lkt-bioair>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Abbrechen</button>
  <button mat-button [mat-dialog-close]="form.dirty ? form.value : null" cdkFocusInitial>
    Speichern
  </button>
</div>
