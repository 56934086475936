import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthQuery, AuthService } from './state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private authService: AuthService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;

    // const isLoggedIn = await this.authQuery.isLoggedIn();
    //
    // if (isLoggedIn) {
    //   return true;
    // }
    //
    // await this.authService.forceLogin();
    //
    // return true;
  }
}
