import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import SignaturePad from 'signature_pad';

import ResizeObserver from 'resize-observer-polyfill';

@Directive({
  selector: 'canvas[signaturePad]',
  exportAs: 'signaturePad',
})
export class SignaturePadDirective implements OnInit, OnDestroy {
  signaturePad: SignaturePad;
  canvas = this.elementRef.nativeElement;
  resizeObserver: ResizeObserver;

  queuedDataURL: string | null = null;

  constructor(private elementRef: ElementRef<HTMLCanvasElement>) {}

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext('2d').scale(ratio, ratio);

    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value

    if (this.queuedDataURL) {
      this.signaturePad.fromDataURL(this.queuedDataURL);
      this.queuedDataURL = null;
    }
  }

  ngOnInit() {
    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: 'rgba(255,255,255)',
    });

    this.resizeObserver = new ResizeObserver(() => {
      this.resizeCanvas();
    });

    this.resizeObserver.observe(this.canvas);
  }

  ngOnDestroy(): void {
    this.signaturePad.off();
    this.resizeObserver.disconnect();
  }
}
