import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { parseISO, startOfDay } from 'date-fns';
import { map } from 'rxjs/operators';
import groupby from 'lodash.groupby';
import { Termin } from '../../state/termin.model';
import { TerminQuery } from '../../state/termin.query';
import { AuftragQuery } from '../../state/auftrag.query';

@Component({
  selector: 'app-view-auftraege',
  templateUrl: './view-auftraege.component.html',
  styleUrls: ['./view-auftraege.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAuftraegeComponent implements OnInit {
  tage;

  constructor(private auftragQuery: AuftragQuery, private terminQuery: TerminQuery) {}

  ngOnInit() {
    this.tage = this.terminQuery.selectAlle().pipe(
      map((termine) => {
        const termineByTag = groupby(termine, (termin: Termin) =>
          startOfDay(parseISO(termin.start)).toISOString()
        );

        return Object.keys(termineByTag).map((datum) => {
          const termine: Termin[] = termineByTag[datum];
          return {
            datum,
            termine,
          };
        });
      })
    );
  }

  selectAuftrag(id: string) {
    return this.auftragQuery.selectEntity(id);
  }
}
